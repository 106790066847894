import { tranlate } from '../LocalizationJson/i18';
import {
  USER_VENDOR_CHAT,
  VENDOR_ADMIN_CHAT,
  VENDOR_VENDOR_CHAT,
} from '../helpers/contants';
export interface routeTypes {
  name: string;
  path: string;
  id: number;
  isPrivate?: boolean;
  Component: React.FC<any>;
  pageProp?: { page: string };
}

export interface CustomKeys {
  label: string;
  value: string;
}

export type country = {
  code: string;
  dial_code: string;
  flag: string;
  name: string;
  _id: string;
};

export type Department = {
  _id: string;
  deptName: string;
};

export enum ApiStatus {
  idle = 'idle',
  loading = 'loading',
  succeeded = 'succeeded',
  failed = 'failed',
}

export enum ProductType {
  RENTAL_AND_PURCHASE = 'RENTAL_AND_PURCHASE',
  RENTAL = 'RENTAL',
  PURCHASE = 'PURCHASE',
}

/// State Types Start
interface Filter {
  fromDate: string;
  toDate: string;
  department: any;
  type: ProductType;
}
export interface ProductState {
  loading: boolean;
  products: null | any;
  error: boolean | string;
  page: number;
  selectedIds: string[];
  selectedProductsDetails: any;
  searchKeywords: string;
  filter: null | Filter;
  actionApiStatus: ApiStatus;
  detailsApiStatus: ApiStatus;
  productDetails: null | any;
  sequenceList: [];
  addProductVariantPayload: any;
  variantStatus: any;
  combinationVariants: any;
  variantScroll: any;
  variantsIds?: any;
}

interface BranchFilter {
  fromDate: string;
  toDate: string;
  status: string;
}

export interface BranchState {
  loading: boolean;
  branches: null | any;
  error: boolean | string;
  page: number;
  selectedIds: string[];
  searchKeywords: string;
  filter: null | BranchFilter;
  branchData: null | any;
  actionApiStatus: ApiStatus;
  existedZone: any[];
  holidayList: any[];
}

export interface CoVendorState {
  apiStatus: ApiStatus;
  permissionTable: any;
  moduleTable: any;
  backendModuleTable: null | any[];
  vendors: null | any;
  filter: null | BranchFilter;
  searchKeywords: string;
  coVendorDetails: null | any;
  vendorToBeDelete: null | string;
  deleteVendorModal: boolean;
}

export interface CommonState {
  countryList: country[];
  departmentList: Department[];
  categories: any[];
  loading: boolean;
  blockNavigation: boolean;
  menu: boolean;
  mobileChat: boolean;
  actionApiStatus: ApiStatus;
  activeMenu: string;
  page: number;
  searchedLocations: any[];
}
export interface StaticContentState {
  data: any;
  loading: boolean;
}
export interface AuthState {
  status: boolean;
  userData: null | any;
  token: null | string;
  disclamerPopUpVisibile: boolean;
  apiStatus: ApiStatus;
  permissionToAdd: boolean;
  tokenFCM?: string;
  rejectBanner?: any;
  rejectReason?: any;
}
export interface CompanyState {
  apiStatus: ApiStatus;
  companyDetails: null | any;
}

export enum AddToOrder {
  rent = 'rent',
  purchase = 'purchase',
  draftRent = 'draftRent',
  draftPurchase = 'draftPurchase',
}

export enum OrderSortKeys {
  updatedAt = 'updatedAt',
  userName = 'userName',
}

export interface ClientState {
  clientList: any;
  clientInfo: any;
  setDetails: any;
  setOrders: any;
  sets: any;
  showMultipleOrdersStatus: boolean;
  selectedSetOrders: any;
  rateAndDiscount: any;
  documents: any;
  associationsList: any;
  page: number;
  searchKeywords: string;
  type: string;
  status: string;
  loading: boolean;
  filter: any;
  editStatus: boolean;
  setOrderLoading: false;
}

export interface GroupState {
  products: any;
  groups: any;
  page: number;
  searchKeywords: string;
  type: string;
  loading: boolean;
  filter: any;
  departmentAndCategory: {
    department: string;
    category: string;
  };
  selectedIds: any;
  selectedAllProducts: any;
  groupDetails?: any;
  tableProduts: any;
  filterStatus?: boolean;
}

export interface OrderState {
  orderDetailsLoader: boolean;
  orders: any;
  error: boolean | string;
  page: number;
  filter: null | any;
  filterItems: null | any;
  searchKeywords: string;
  productOrderList: [] | any;
  rentProdctsList: any;
  orderDetails: null | any;
  setNameList: any;
  episodeNameList: any;
  locationList: any;
  editedItem: EditedProductOrder | null;
  cancleOrderModalData: {
    isOrderCancle: boolean;
    orderDetails?: any;
  } | null;
  addToOrderType: null | AddToOrder;
  worksheetDetails: ShiftData | null;
  deliveryAddressModalData: {
    mode: ModalMode;
    itemDetails?: any;
  } | null;
  listLoader: {
    setNames: boolean;
    locations: boolean;
    updateAddress: boolean;
  };
  rentalModalDetails: {
    state: boolean;
    itemDetails: any | null;
    formDetails: any;
    cartId: string | null;
    rentalDetails: any;
  };
  quotationShifts: any;
  qoutationCartDetails: any;
  sortKey: OrderSortKeys;
  sortMode: 1 | -1;
  disableSubmitForm: boolean;
  draftDeliveryAddressModalState: boolean;
  draftDeliveryAddressEditedId: string | null;
  locationModelStatus: boolean;
  orderLocationList: any;
  notesModalData: any | null;
  allItemsModalList: Item[];
  locationModalData: any | null;
}

export enum ModalMode {
  edit = 'edit',
  add = 'add',
}

/// State Types End
export enum CrudOperation {
  create = 'create',
  update = 'update',
  delete = 'delete',
}

export enum TableAction {
  delete = 'delete',
  status = 'status',
}

export interface Coordinate {
  lat: number;
  lng: number;
}

export interface Branch {
  zone?: {
    coordinates: Coordinate[] | any;
  };
  branchName: string;
}

export enum OrderEditOptionValues {
  edit_quantity = 'edit_quantity',
  edit_status = 'edit_status',
  cancel_order = 'cancel_order',
  add_note = 'add_note',
}

export enum OrderRentEditOptionValues {
  approve = 'approve',
  reject = 'reject',
  edit_quantity = 'edit_quantity',
  edit_status = 'edit_status',
  view_workheet = 'view_workheet',
  add_note = 'add_note',
}

export const EditOptions = {
  // edit_quantity: {
  //   label: tranlate('orders.detail.edit_quantity'),
  //   value: OrderEditOptionValues.edit_quantity,
  // },
  // edit_quantity: {
  //   label: '',
  //   value: '',
  // },
  edit_status: {
    label: tranlate('orders.detail.edit_order_status'),
    value: OrderEditOptionValues.edit_status,
  },
  // cancel_order: {
  //   label: tranlate('orders.detail.cancel_order'),
  //   value: OrderEditOptionValues.cancel_order,
  // },
  // add_note: {
  //   label: tranlate('orders.detail.add_note'),
  //   value: OrderRentEditOptionValues.add_note,
  // },
};
export const DraftOptions = {
  edit_quantity: {
    label: tranlate('orders.detail.edit_quantity'),
    value: OrderEditOptionValues.edit_quantity,
  },
  cancel_order: {
    label: tranlate('orders.detail.remove_item'),
    value: 'remove',
  },
};

export const EditRentOptions = {
  // add_quantity: {
  //   label: tranlate('orders.detail.add_note'),
  //   value: OrderRentEditOptionValues.add_note,
  // },
  // approve: {
  //   label: tranlate('orders.detail.approve'),
  //   value: OrderRentEditOptionValues.approve,
  // },
  // reject: {
  //   label: tranlate('orders.detail.reject'),
  //   value: OrderRentEditOptionValues.reject,
  // },
  add_note: {
    label: tranlate('orders.detail.add_note'),
    value: OrderRentEditOptionValues.add_note,
  },
};

export const EditRentOptionsWithEditAndStatus = {
  // view_workheet: {
  //   label: tranlate('orders.detail.view_worksheet'),
  //   value: OrderRentEditOptionValues.view_workheet,
  // },
  add_note: {
    label: tranlate('orders.detail.add_note'),
    value: OrderRentEditOptionValues.add_note,
  },
  // edit_quantity: {
  //   label: tranlate('orders.detail.edit_quantity'),
  //   value: OrderRentEditOptionValues.edit_quantity,
  // },
  edit_status: {
    label: tranlate('orders.detail.edit_order_status'),
    value: OrderRentEditOptionValues.edit_status,
  },
};

export const EditRentOptionsWithoutWorksheet = {
  // view_workheet: {
  //   label: tranlate('orders.detail.view_worksheet'),
  //   value: OrderRentEditOptionValues.view_workheet,
  // },
  add_quantity: {
    label: tranlate('orders.detail.add_note'),
    value: OrderRentEditOptionValues.add_note,
  },
  edit_quantity: {
    label: tranlate('orders.detail.edit_quantity'),
    value: OrderRentEditOptionValues.edit_quantity,
  },
  edit_status: {
    label: tranlate('orders.detail.edit_order_status'),
    value: OrderRentEditOptionValues.edit_status,
  },
};

export enum ShiftOptions {
  edit_shift = 'edit_shift',
  delete = 'delete_shift',
  add_note_shift = 'add_note_shift',
  save_edit_shift = 'save_edit_shift',
  cancle = 'cancle',
}

export const ShiftActions = {
  edit_shift: {
    label: 'Edit',
    value: ShiftOptions.edit_shift,
  },
  delete: {
    label: 'Delete',
    value: ShiftOptions.delete,
  },
  add_note: {
    label: tranlate('orders.detail.add_note'),
    value: ShiftOptions.add_note_shift,
  },
};

export const ShiftEditActions = {
  save: {
    label: 'Save',
    value: ShiftOptions.save_edit_shift,
  },
  cancel: {
    label: 'Cancel',
    value: ShiftOptions.cancle,
  },
};

export interface EditedProductOrder {
  mode: OrderEditOptionValues | null;
  index: number;
  product: any;
}
export type SendMessageType = {
  contactId: string;
  contactType: string;
} & Partial<MessageType>;

export interface ChatState {
  chats: any[];
  loadingChats: boolean;
  activeChatId: string | null;
  chatMap: Record<string, Chat>;
  selectedQuote: Quotation | null;
  // file progress
  fileProgress: Record<string, Record<string, number>>;
  // Not needed but we can keep for future
  currentActiveUserId?: string | null;
  messages: {
    messagesMap: Record<string, any>;
    lastRead: Record<string, Date | number>;
    loading: Record<string, boolean>;
    page: Record<string, ChatPage>;
  };
  users: {
    isLoading: boolean;
    // map with room id with message
    usersMap: Record<string, any>;
  };
  orderToCreate: SendMessageType | null;
  selectedIds: any;
  productsAndServiceList: any;
  editedOrderQoutationId: string | null;
  quoteOrderMap: Record<string, string>;
  lastActiveChat?: string;
  employeeList: Employee[];
}
export interface Employee {
  firstName: string;
  lastName: string;
  profilePicture: string;
  _id: string;
}
export interface ChatPage {
  nextPage: number;
  hasMore: boolean;
  limit: number;
  loading: boolean;
}
export interface Chat {
  _id?: string;
  members?: string[];
  type?: string;
  businessChatType?: string;
  lastMsgCreated?: number;
  lastMsgId?: string;
  lastMessageDetail?: LastMessageDetail;
  contactDetails?: ContactDetails;
  noOfUnreadMessage?: number;
  userOnlineStatus?: UserDetails | null;
  isNewChat?: boolean;
}

export interface ContactDetails {
  _id?: string;
  profilePicture?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
}

export interface LastMessageDetail {
  _id?: string;
  senderId?: string;
  receiverId?: string;
  message?: string;
  messageType?: MessageVariant;
  mediaDetail?: MediaDetails[];
}
export interface MessageType {
  _id?: string;
  type?: string;
  status?: string;
  isRead?: string[];
  isDelivered?: string[];
  senderId: string;
  receiverId?: string;
  chatId?: string;
  message: string;
  messageType: MessageVariant;
  created?: number;
  cartDetail?: CartDetails;
  createdAt: Date;
  user?: null;
  intermediateId?: string;
  quotationDetail?: Quotation;
  locationDetail?: LocationDetails;
  mediaDetail?: MediaDetails[];
  quoteAddressType?: string;
}

export interface UserDetails {
  isOnline: Boolean;
  lastSeen: number;
  contactId: string;
}
export interface Quotation {
  _id?: string;
  documents?: {
    mediaUrl: string;
    mediaExtension: string;
    mediaName: string;
    mediaSize: string;
  }[];
  quoteAddressType?: string;
  locationDetail?: LocationDetails;
  userAddressId?: string;
  notes?: string;
  startTime?: Date;
  endTime?: Date;
  createdBy?: string;
  created?: number;
  createdAt?: Date;
  updatedAt?: Date;
  setName?: string;
  quotationId: string;
  isCartCreated?: boolean;
  productionName?: string;
  productionType: string;
  productionScheduleType: string;
  episodeName?: string;
  setScheduleType?: string;
  deptName?: string;
}
export interface LocationDetails {
  _id?: string;
  userId?: string;
  addressLabel?: string;
  address?: {
    stateProvince: string;
    cityTown: string;
    locationName: string;
    nickname: string;
    addressLine2: string;
    addressLine1: string;
  };
  buildingName?: string;
  apartment?: string;
  deliveryStatus?: string;
  location?: Location;
  created?: number;
  createdAt?: Date;
  updatedAt?: Date;
  isPrimary?: boolean;
}

export interface Location {
  type?: string;
  coordinates?: number[];
}

export interface MediaDetails {
  mediaUrl: string;
  mediaExtension: string;
  mediaName: string;
  mediaSize: string;
  mediaLocalUrl?: string;
  failed?: boolean;
}
export interface CartDetails {
  isCartActive?: boolean;
  quotationId?: string;
  productName?: string;
  qty?: number;
  noOfItems?: number;
  location?: Location;
  grandTotal?: string;
}

export interface Location {
  buildingName?: string;
  address?: string;
}
export interface NotificationFCM {
  from?: string;
  messageId?: string;
  notification?: {
    body?: string;
    title?: string;
  };
  data?: NotificationData;
}

export interface NotificationData {
  chatId?: string;
  senderImage?: string;
  notificationType?: NotificationType;
  senderId?: string;
  messageType?: string;
  message?: string;
  senderType?: string;
  senderName?: string;
  vendorOrderId?: string;
}
export type BusinessChatType =
  | typeof USER_VENDOR_CHAT
  | typeof VENDOR_VENDOR_CHAT
  | typeof VENDOR_ADMIN_CHAT;
export type NotificationType = 'CHAT' | 'ORDER';
export type MessageVariant =
  | 'TEXT'
  | 'QUOTATION'
  | 'ORDER'
  | 'MEDIA'
  | 'IMAGE'
  | 'DOCS'
  | 'VIDEO'
  | 'CART';
export interface RiskManagementState {
  loading: boolean;
  editedItem: EditedProductOrder | null;
  riskList: null | any;
  riskListData: null | any;
  error: boolean | string;
  filter: null | any;
  searchKeywords: string;
  userData: null | any;
  currentTab: number;
  ordersList: any;
  accountList: any;
  usersList: any;
  entitylist: null | any;
  countrylist: any;
  citylist: any;
  departmentList: any;
  studioList: any;
  companyList: any;
  productionList: any;
  pageNo?: number;
  universityList: any;
  degreeList: any;
  orderrequestlist: any;
  cancleOrderModalDataRequest: {
    isOrderCancle: boolean;
    orderDetails?: any;
  } | null;
}
export interface Country {
  name: string;
  alpha2Code: string;
  alpha3Code: string;
  region: string;
  subregion: string;
  population: number;
}

// Type for individual address component in the API response
export interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

// Type for geographical location (latitude and longitude)
export interface GeoLocation {
  lat: number;
  lng: number;
}

// Type for geometry field in the location response
export interface Geometry {
  location: GeoLocation;
}

// Type for each result in the location API response
export interface Result {
  address_components: AddressComponent[];
  geometry: Geometry;
}

// Main API response type for fetchLocation function
export interface FetchLocationResponse {
  results: Result[];
  status: string;
}

// Define the shape of the function that fetches location data
export type FetchLocationFunction = (
  code: string,
) => Promise<FetchLocationResponse>;

// types.ts

export interface UploadedImage {
  url: string;
}

export interface VariantProduct {
  propertyTitle: string;
  propertyValue: string;
  isCurrent?: boolean;
  productId?: { _id: string };
}

export interface FormData {
  thumbnailImg?: string;
  type: string;
  images?: string[];
  video?: string;
  variantProducts?: VariantProduct[];
  rentDiscount?: number;
  rentDiscountType?: string;
  [key: string]: any;
}

export enum NOTES_TYPE {
  ORDER_NOTE = 'ORDER_NOTE',
  SHIFT_NOTE = 'SHIFT_NOTE',
}

export enum VIEW_NOTES_TYPE {
  VIEW_ALL_NOTES = 'VIEW_ALL_NOTES',
  SINGLE_NOTES = 'SINGLE_NOTES',
}

export interface LocationPurpose {
  addressType: string;
  address: string;
  apartment: string | null;
  buildingName: string | null;
  location: {
    type: string;
    coordinates: [number, number];
  };
  productionType: string;
  productionId: string;
  productionName: string;
  productionImage: string | null;
  setId: string;
  setName: string;
  setImage: string | null;
  locationId: string;
  locationName: string;
  locationImage: string | null;
  episodeId: string | null;
  episodeName: string | null;
  episodeImage: string | null;
  episodeNumber: string | null;
  movie: string | null;
  productionScheduleType: string;
  setScheduleType: string;
  locationPurpose: string[];
  departmentId: string;
  deptName: string;
  departmentImage: string;
}

export interface ApprovalTrack {
  _id: string;
  title: string;
  body: string;
  updatedAt?: number;
}

export interface StatusTrack {
  _id?: string;
  status: string;
  statusUpdatedAt: number;
}

export interface DeliveryAddress extends LocationPurpose {}

export interface Note {
  _id: string;
  userId: string;
  name: string;
  userType: string;
  note: string;
  updatedAt: string;
  createdAt: string;
}
export interface Item {
  _id: string;
  orderId: string;
  vendorOrderId: string;
  userId: string;
  userName: string;
  branchId: string;
  productId: string;
  productName: string;
  productImage: string;
  productionId: string;
  productionName: string;
  qty: number;
  itemTotal: number;
  isRushEnable: boolean;
  rushFee: number;
  rushEta: string | null;
  eta: number;
  edd?: string;
  status: string;
  paymentStatus: string;
  orderStatus: string;
  vendorOrderStatus: string;
  deliveryAddress: DeliveryAddress;
  created: number;
  createdAt: string;
  updatedAt: string;
  mileageCost: number;
  distance: number;
  mileageRate: number;
  orderBy: {
    id: string;
    name: string;
  };
  isChildProduct?: boolean | null;
  email: string | null;
  isVendorCartOrder: string | null;
  itemType: string;
  categoryId: string;
  categoryName: string;
  categoryImage: string;
  vendorId: string;
  vendorBusinessName: string;
  logoImage: string;
  bgImage: string[];
  bannerImage: string;
  shoppingMode: string;
  price: number;
  perDiemCost?: number | null;
  travelCost?: number | null;
  isReturnAble: boolean;
  cancellationFee?: number | null;
  deliveryFee: number;
  deliveryType: string;
  discountType?: string | null;
  discount?: number | null;
  totalPrice: number;
  finalPrice: number;
  approvalTrack?: ApprovalTrack[];
  isApprovalOrder?: boolean;
  overallStatus?: string;
  statusTrack: StatusTrack[];
  notes: Note[];
  note?: string;
}

export interface LocationAdress {
  locationId: string;
  locationName: string;
  items: Item[];
}

export interface Set {
  addressType: string;
  setId: string;
  setName: string;
  locations: LocationAdress[];
}

export interface Shift {
  _id: string;
  unit: number;
  actualStartTime: string;
  actualEndTime: string;
  shiftCoverage: string;
  duration: number;
  cost: number;
  unitCost: number;
  OtCost: number;
  DtCost: number;
  status: string;
  approveBy: string;
  updatedBy: Record<string, unknown>;
}

export interface ShiftData {
  data: Shift[];
  total: number;
  pageNo: number;
  totalPage: number;
  nextHit: number;
  limit: number;
}
