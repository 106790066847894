import { useNavigate } from 'react-router-dom';

interface Props {
  buttonChildren?: JSX.Element | JSX.Element[];
  pageTitle: String;
  pageName?: string;
  navigateUrl?: string | number;
  isNestedBreadcrum?: boolean;
  breadcrumNested?: JSX.Element;
}

export default function PageHeading(props: Props) {
  const {
    buttonChildren,
    pageTitle,
    pageName,
    navigateUrl,
    isNestedBreadcrum,
    breadcrumNested,
  } = props;
  const navigate: any = useNavigate();
  return (
    <div className="pageHeading">
      <div className="headings">
        <h3>{pageTitle}</h3>
        {isNestedBreadcrum ? (
          <>{breadcrumNested}</>
        ) : (
          <>
            {pageName ? (
              <ul className="breadCrums">
                <li
                  onClick={() =>
                    navigateUrl ? navigate(navigateUrl) : undefined
                  }
                >
                  {pageTitle}
                </li>
                <li>{pageName}</li>
              </ul>
            ) : null}
          </>
        )}
      </div>
      <div className="buttons">{buttonChildren}</div>
    </div>
  );
}
