import { Popover } from '@mui/material';
import React from 'react';

interface Props {
  data: Array<String>;
  limit: number;
}

export default function ViewInTags(props: Props) {
  const { data, limit } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div className="viewInTags">
      <ul>
        {data.slice(0, limit).map((v: any) => {
          return <li>{v}</li>;
        })}
        {data.length > limit && (
          <li onClick={handleClick}>+{data.length - limit}</li>
        )}
        <Popover
          id={'viewInTagsPopover'}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div className="viewInTags viewInTagsPopover">
            <ul>
              {data.map((v: any) => {
                return <li>{v}</li>;
              })}
            </ul>
          </div>
        </Popover>
      </ul>
    </div>
  );
}
