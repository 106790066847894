interface Props {
  children?: JSX.Element | JSX.Element[];
  heading: String;
  hideHeader?: boolean;
}

export default function SectionContainer(props: Props) {
  const { children, heading, hideHeader = true } = props;
  return (
    <div className="sectionContainer">
      {hideHeader ? (
        <div className="sectionHeadings">
          <h3>{heading}</h3>
        </div>
      ) : null}
      <div className={`sectionBody ${hideHeader ? '' : 'no_heading'}`}>
        {children}
      </div>
    </div>
  );
}
