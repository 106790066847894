import { Dialog, Grid, IconButton } from '@mui/material';
import React, { useState } from 'react';
import Images from '../../../Utils/images';
import CustomButton from '../../../Components/CustomButton';
import { useTranslation } from 'react-i18next';
import InputField from '../../../Components/Input';
import Textarea from '../../../Components/Textarea';
import CustomSelect from '../../../Components/CustomSelect';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CancelFormInterface,
  CancelFormSchema,
} from '../../../Utils/yupvalidations';
import {
  cancleOrder,
  getAndOrderDetails,
  getAndSetOrderList,
  getOrderProductsList,
  rentApproveReject,
  setCancelModalData,
  setEditedItemStore,
} from '../../../redux/order.slice';
import { notify } from '../../../Utils/toastify';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ORDER_PRODUCT_TYPES } from '../../../Utils/constantData';
import { preventSubmitOnEnter } from '../../../Utils/helperFns';
interface Props {
  // open?: boolean
  open: boolean;
  handleClose?: any;
  onClick?: () => void;
  orderid?: string;
}

export default function RejectRentDailog(props: Props) {
  const { open, handleClose, orderid } = props;
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t: tC } = useTranslation('translation', {
    keyPrefix: 'Common',
  });
  const { t } = useTranslation('translation', {
    keyPrefix: 'orders.detail',
  });
  const { t: tO } = useTranslation('translation', {
    keyPrefix: 'orders',
  });
  const { t: tR } = useTranslation('translation', {
    keyPrefix: 'orders.detail.reasons',
  });

  const [loading, setLoading] = useState<boolean>(false);

  const reasons = [
    tR('serviceNotAvail'),
    tR('productNotAvail'),
    tR('productStock'),
    tR('productQuantity'),
    tR('serviceQuantity'),
    tR('data'),
    tR('time'),
    // tR('denied'),
    // tR('others'),
  ];

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(CancelFormSchema),
    mode: 'onTouched',
  });

  const onSubmit = (data: CancelFormInterface) => {
    setLoading(true);
    rentApproveReject(
      {
        id: orderid,
        status: 'REJECTED',
        reason: data.cancelReason,
        note: data.cancelNotes,
      },
      (data: any) => {
        if (data) {
          handleClose && handleClose('submit');
          id && getAndOrderDetails(id);
        }
      },
    );
  }; // console.log(data)

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => preventSubmitOnEnter(e)}
      >
        <div className="dialogWrapper warning orderAddress w__600">
          <div className="dialogHeader flex sps_between">
            <h4 className="small">{t('reject')}</h4>
            <IconButton onClick={handleClose}>
              <img src={Images.CLOSE} alt={tC('close')} />
            </IconButton>
          </div>
          <div className="doalogBody small">
            <div className="formContainer">
              <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  <CustomSelect
                    id="reason"
                    labelText={t('choose_a_reason_for_reject')}
                    placeHolderText={t('reasonPlaceholder')}
                    name="cancelReason"
                    control={control}
                    error={!!errors['cancelReason']}
                    helperText={
                      !!errors['cancelReason']
                        ? `${errors['cancelReason'].message}`
                        : ''
                    }
                    options={reasons}
                    requiredField
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Textarea
                    id="notes"
                    label={tC('notes')}
                    name="cancelNotes"
                    placeholder={tC('enter_notes')}
                    control={control}
                    error={!!errors['cancelNotes']}
                    helperText={
                      !!errors['cancelNotes']
                        ? `${errors['cancelNotes'].message}`
                        : undefined
                    }
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="dialogActions flex end">
            <CustomButton
              size="large"
              variant="outlined"
              text={tC('btnTxt.cancel')}
              showIcon={false}
              width="auto"
              type="button"
              id="accept"
              loading={false}
              onClick={handleClose}
            />
            <CustomButton
              size="large"
              variant="contained"
              text={tC('btnTxt.save')}
              showIcon={false}
              width="auto"
              type="submit"
              id="accept"
              disabled={!isValid}
              loading={loading}
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
}
