export const LocationListLoader = 'LocationListLoader';

export const AddNotesLoader = 'AddNotesLoader';

export const ShiftsLoader = 'ShiftsLoader';

export const ShiftUpdateLoader = 'ShiftUpdateLoader';

export const AllLocationItemsLoader = 'AllLocationItemsLoader';

export const DeleteOrderItemsLoader = 'DeleteOrderItemsLoader';

export const locationUpdateLoader = 'locationUpdateLoader';
