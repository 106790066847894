// redux/loaderSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store'; // Import RootState from your store

// Define the initial state type
interface LoaderState {
  [key: string]: boolean; // Key is the loader type, value is a boolean
}

// Initial state
const initialState: LoaderState = {};

// Create the slice
const loaderSlice = createSlice({
  name: 'loaders',
  initialState,
  reducers: {
    startLoading: (state, action: PayloadAction<string>) => {
      state[action.payload] = true; // Set loader to true
    },
    stopLoading: (state, action: PayloadAction<string>) => {
      state[action.payload] = false; // Set loader to false
    },
  },
});

// Export actions
export const { startLoading, stopLoading } = loaderSlice.actions;

// Selector to get a specific loader's state
export const selectLoader = (state: RootState, loaderType: string): boolean =>
  state.loaders[loaderType] || false;

// Export reducer
export default loaderSlice.reducer;
