import LOGO from '../assets/images/logo.svg';
import HELP_CIRCLE from '../assets/images/help-circle.svg';
import USER_IMAGE from '../assets/images/user.png';
import CIRCLE_TICK from '../assets/images/tick-circle.svg';
// import WELCOME_ILLUSTRATOR from '../assets/images/illustration.svg'
// import SUCCESS_GIF from '../assets/images/aa.gif'
import CALENDAR from '../assets/images/calendar.svg';
import CHECK from '../assets/images/check-tick.svg';
import CHECK_TICK from '../assets/images/tick-square.svg';
import CHECK_RED from '../assets/images/tick-square-red.svg';
import CHECK_TICK_WHITE from '../assets/images/tick-square-white.svg';
import CHECK_TICK_CHECKED_WHITE from '../assets/images/tick-square-checked-white.svg';
// import CHECK_TICK_RED from '../assets/images/check-tick-red.svg'
import ATTACHMENT from '../assets/images/attachment.svg';
import WARNING from '../assets/images/warning.svg';
import CLOSE from '../assets/images/close.svg';
import LOADING from '../assets/images/loading.svg';
import DASHBOARD from '../assets/images/dashboard.svg';
import DASHBOARD_ACTIVE from '../assets/images/dashboard-active.svg';
import ORDERS from '../assets/images/orders.svg';
import ORDERS_ACTIVE from '../assets/images/orders-active.svg';
import PRODUCTS from '../assets/images/products.svg';
import PRODUCTS_ACTIVE from '../assets/images/products-active.svg';
import BRANCH from '../assets/images/branch.svg';
import BRANCH_ACTIVE from '../assets/images/branch-active.svg';
import FINANCIALS from '../assets/images/financials.svg';
import FINANCIALS_ACTIVE from '../assets/images/financials-active.svg';
import CHATS from '../assets/images/message.svg';
import CHATS_ACTIVE from '../assets/images/message-active.svg';
import COMPANY from '../assets/images/company.svg';
import COMPANY_ACTIVE from '../assets/images/company-active.svg';
import CLIENTS from '../assets/images/clients.svg';
import CLIENTS_ACTIVE from '../assets/images/clients-active.svg';
import NO_DATA from '../assets/images/no-data.svg';
import FILTER from '../assets/images/filter-outline.svg';
import FILTER_WHITE from '../assets/images/filter-outline-white.svg';
import FILE_ICON from '../assets/images/file.svg';
import DELETE_IC from '../assets/images/delete.svg';
import IMAGE_IC from '../assets/images/image_ic.svg';
import VIDEO_IC from '../assets/images/video.svg';
import NO_DATA_FOUND from '../assets/images/no-data-found.svg';
import NO_RECORDS_FOUND from '../assets/images/no-records-found.svg';
import EDIT_IC from '../assets/images/edit-ic.svg';
import TRASH from '../assets/images/trash.svg';
import BELL from '../assets/images/notification-bing.svg';
import BELL_WHITE from '../assets/images/notification-white.svg';
import SEARCH from '../assets/images/search.svg';
import SEARCH_BLACK from '../assets/images/search-black.svg';
import SUCCESS_IC from '../assets/images/success-ic.svg';
import ERROR_IC from '../assets/images/error-ic.svg';
import LOADER_TWO from '../assets/images/loader-2.gif';
// import CROP_IMAGE from '../assets/images/crop-image.png'
import IMAGE_PLACEHOLDER from '../assets/images/image-placeholder.png';
import CALL from '../assets/images/call.svg';
import EMAIL from '../assets/images/email.svg';
import HEADQUARTERS from '../assets/images/headquarter.svg';
// import ERROR from '../assets/images/error.svg'
import NO_INTERNET from '../assets/images/under-development.svg';
import MAP_PIN from '../assets/images/map_pin.svg';
import VISSIBILITY from '../assets/images/vissibility.svg';
import VISSIBILITY_OFF from '../assets/images/vissibility-off.svg';
import CLOSE_CIRCLE from '../assets/images/close-circle.svg';
import ARROW_DOWN from '../assets/images/arrow-down.svg';
import INFO_ICON from '../assets/images/info-icon.svg';
import INFO_ICON_LIGHT from '../assets/images/info-icon-light.svg';
import DOWNLOAD from '../assets/images/download.svg';
import DOWNLOAD_LIGHT from '../assets/images/download-file-light.svg';
import PRODUCT_INFO from '../assets/images/product-info.svg';
import CLOSE_CTA from '../assets/images/close-cta.svg';
import CHECL_CTA from '../assets/images/check-cta.svg';
import WELCOME from '../assets/images/welcome.svg';
import CO_VENDOR from '../assets/images/co-vendor.svg';
import CO_VENDOR_ACTIVE from '../assets/images/co-vendor-active.svg';
import CHAT from '../assets/images/chat.svg';
import DOWNLOAD_FILE from '../assets/images/download-file.svg';
import DOWNLOAD_FILE_BG from '../assets/images/download-file-bg.svg';
import REJECT_ORDER from '../assets/images/reject-order.svg';
import ADD_IC from '../assets/images/add.svg';
import EXCEL_IC from '../assets/images/excel-ic.svg';
import DOCUMENT from '../assets/images/document-ic.svg';
import GALLERY from '../assets/images/gallery-ic.svg';
import IC_CAMERA from '../assets/images/ic-camera.svg';
import IC_DOCUMENT from '../assets/images/ic-document.svg';
import IC_VIDEO from '../assets/images/ic-video.svg';
import IC_MEDIA from '../assets/images/ic-media.svg';
import SINGLE_TICK from '../assets/images/single-check.svg';
import DOUBLE_TICK from '../assets/images/double-check.svg';
import DOUBLE_TICK_UNREAD from '../assets/images/double-check-unread.svg';
import ONLINE from '../assets/images/online.svg';
import SEND from '../assets/images/send.svg';
import RECEIPT_EDIT from '../assets/images/receipt-edit.svg';
import PREVIEW_IC from '../assets/images/preview-ic.svg';
import CANCEL_CIRCLE from '../assets/images/cancel-circle.svg';
import CONFIRM_TICK from '../assets/images/confirm-tick.svg';
import DOLLAR_CIRCLE from '../assets/images/dollar-circle.svg';
import MONEY_SEND from '../assets/images/money-send.svg';
import MONEY_TIME from '../assets/images/money-time.svg';
import UNREAD_NOTIFICATION from '../assets/images/unread-notification.svg';
import PUSH_NOTIFICATION from '../assets/images/push-notification.svg';
import NOT_FOUND from '../assets/images/not-found.svg';
import NO_NOTIFICATIONS from '../assets/images/no-notifications.svg';
import SLIDE_PREV from '../assets/images/slidePrev.svg';
import SLIDE_NEXT from '../assets/images/slideNext.svg';
import CLIENT_IMAGE from '../assets/images/client-image.png';
import EYE_PRIMARY from '../assets/images/eye-primary.svg';
import TICK_SQUARE_DISABLED from '../assets/images/tick-square-disabled.svg';
import UNCHECK_DISABLED from '../assets/images/uncheck-disabled.svg';
import SORT from '../assets/images/sort.svg';
import SORT_ASCENDING from '../assets/images/ascending.svg';
import SORT_DESCENDING from '../assets/images/descending.svg';
import DRIVER from '../assets/images/driver.svg';
import DRIVER_ACTIVE from '../assets/images/driver-active.svg';
import FAQ_ILLUSTRATOR from '../assets/images/faq-illustrator.svg';
import ARROW_UP from '../assets/images/arrow-up.svg';
import CHIP_DELETE from '../assets/images/chip-delete.svg'
import RISK_MNG from '../assets/images/risk-mng.svg'
import RISK_MNG_ACTIVE from '../assets/images/risk-mng-active.svg'
import BLOCKED_IC from '../assets/images/block-icon.svg'
import USER_PLACEHOLDER from '../assets/images/user-placeholder.png'
import PLACEHOLDET_IMAGE from '../assets/images/placeholder-image.png'
import PROFESSION_IMAGE from '../assets/images/profession-image.png'
import PRODUCTION_IMAGE from '../assets/images/production-image.png'
import EDUCATION_IMAGE from '../assets/images/Education.png'
import ADD_CIRCLE_IC from '../assets/images/add-circle.svg'
import STUDIO_IC from '../assets/images/studio_ic.svg'
const STATIC_IMAGES: any = process.env.STATIC_IMAGES || {};

const Images = {
  AUTH_BG_1: STATIC_IMAGES.AUTH_BG_1 || '',
  AUTH_BG_2: STATIC_IMAGES.AUTH_BG_2 || '',
  AUTH_BG_3: STATIC_IMAGES.AUTH_BG_3 || '',
  AUTH_BG_4: STATIC_IMAGES.AUTH_BG_4 || '',
  AUTH_BG_5: STATIC_IMAGES.AUTH_BG_5 || '',
  AUTH_BG_6: STATIC_IMAGES.AUTH_BG_6 || '',
  AUTH_BG_7: STATIC_IMAGES.AUTH_BG_7 || '',
  AUTH_BG_8: STATIC_IMAGES.AUTH_BG_8 || '',
  AUTH_BG_9: STATIC_IMAGES.AUTH_BG_9 || '',
  AUTH_BG_10: STATIC_IMAGES.AUTH_BG_10 || '',
  LOGO,
  HELP_CIRCLE,
  USER_IMAGE,
  CIRCLE_TICK,
  WELCOME_ILLUSTRATOR: STATIC_IMAGES.WELCOME_ILLUSTRATOR || '',
  SUCCESS_GIF: STATIC_IMAGES.SUCCESS_GIF || '',
  CALENDAR,
  CHECK,
  CHECK_TICK,
  ATTACHMENT,
  WARNING,
  CLOSE,
  CHECK_RED,
  CHECK_TICK_WHITE,
  CHECK_TICK_CHECKED_WHITE,
  // CHECK_TICK_RED,
  LOADING,
  DASHBOARD,
  DASHBOARD_ACTIVE,
  ORDERS,
  ORDERS_ACTIVE,
  PRODUCTS,
  PRODUCTS_ACTIVE,
  BRANCH,
  BRANCH_ACTIVE,
  FINANCIALS,
  FINANCIALS_ACTIVE,
  CHATS,
  CHATS_ACTIVE,
  COMPANY,
  COMPANY_ACTIVE,
  CLIENTS,
  CLIENTS_ACTIVE,
  NO_DATA,
  FILTER,
  FILTER_WHITE,
  FILE_ICON,
  DELETE_IC,
  IMAGE_IC,
  VIDEO_IC,
  NO_DATA_FOUND,
  EDIT_IC,
  TRASH,
  BELL,
  SEARCH,
  SEARCH_BLACK,
  BELL_WHITE,
  SUCCESS_IC,
  ERROR_IC,
  LOADER_TWO,
  CROP_IMAGE: STATIC_IMAGES.CROP_IMAGE || '',
  IMAGE_PLACEHOLDER,
  CALL,
  EMAIL,
  HEADQUARTERS,
  ERROR: STATIC_IMAGES.ERROR || '',
  NO_INTERNET,
  MAP_PIN,
  NO_RECORDS_FOUND,
  VISSIBILITY,
  VISSIBILITY_OFF,
  CLOSE_CIRCLE,
  ARROW_DOWN,
  INFO_ICON,
  INFO_ICON_LIGHT,
  DOWNLOAD,
  PRODUCT_INFO,
  CLOSE_CTA,
  CHECL_CTA,
  WELCOME,
  CO_VENDOR,
  CO_VENDOR_ACTIVE,
  CHAT,
  DOWNLOAD_FILE,
  REJECT_ORDER,
  ADD_IC,
  EXCEL_IC,
  DOCUMENT,
  GALLERY,
  IC_CAMERA,
  IC_DOCUMENT,
  IC_MEDIA,
  SINGLE_TICK,
  DOUBLE_TICK,
  DOUBLE_TICK_UNREAD,
  ONLINE,
  SEND,
  DOWNLOAD_LIGHT,
  RECEIPT_EDIT,
  IC_VIDEO,
  PREVIEW_IC,
  CANCEL_CIRCLE,
  CONFIRM_TICK,
  DOLLAR_CIRCLE,
  MONEY_SEND,
  MONEY_TIME,
  UNREAD_NOTIFICATION,
  PUSH_NOTIFICATION,
  NOT_FOUND,
  NO_NOTIFICATIONS,
  SLIDE_NEXT,
  SLIDE_PREV,
  CLIENT_IMAGE,
  DOWNLOAD_FILE_BG,
  EYE_PRIMARY,
  TICK_SQUARE_DISABLED,
  UNCHECK_DISABLED,
  SORT,
  SORT_ASCENDING,
  SORT_DESCENDING,
  DRIVER,
  DRIVER_ACTIVE,
  FAQ_ILLUSTRATOR,
  ARROW_UP,
  CHIP_DELETE,
  RISK_MNG,
  RISK_MNG_ACTIVE,
  BLOCKED_IC,
  USER_PLACEHOLDER,
  PLACEHOLDET_IMAGE,
  EDUCATION_IMAGE,
  PROFESSION_IMAGE,
  PRODUCTION_IMAGE,
  ADD_CIRCLE_IC,
  STUDIO_IC
};

export default Images;
