import React from 'react';
import { Box, FormControl, FormHelperText, FormLabel } from '@mui/material';
import { Controller } from 'react-hook-form';
interface Props {
  id: string;
  label?: string;
  placeholder?: string;
  name: string;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
  defaultValue?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => any;
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  onBlur?:
    | React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  value?: string;
  control?: null | any;
  rows?: number;
  requiredField?: boolean | any;
  getValues?: any;
  charLimit?: number;
  highLighted?: boolean;
}

const Textarea: React.FC<Props> = (props) => {
  const {
    id,
    label,
    placeholder,
    name,
    helperText,
    error,
    disabled,
    defaultValue,
    value,
    onBlur,
    onChange,
    control,
    onKeyDown,
    onPaste,
    rows,
    requiredField,
    getValues,
    charLimit,
    highLighted,
  } = props;

  return (
    <Box className={`${highLighted ? 'highLighted' : ''}`}>
      <FormControl
        fullWidth
        className={`textArea ${error ? 'textarea_error' : ''}`}
        error={error ? true : false}
      >
        <FormLabel className="formLabel">
          {label} {requiredField ? <sup>*</sup> : null}
        </FormLabel>
        {control ? (
          <>
            <Controller
              name={name}
              control={control}
              defaultValue={defaultValue}
              render={({ field }) => (
                <textarea
                  disabled={disabled}
                  id={id}
                  rows={rows}
                  placeholder={placeholder}
                  {...field}
                  onKeyDown={
                    onKeyDown
                      ? onKeyDown
                      : (e) => {
                          if (
                            e.ctrlKey ||
                            e.shiftKey ||
                            e.altKey ||
                            e.metaKey ||
                            e.key == 'Enter'
                          ) {
                            e.stopPropagation();
                            // If Enter key is pressed and character limit is exceeded, prevent default action
                            if (
                              e.key === 'Enter' &&
                              charLimit &&
                              e.target?.value?.length >= charLimit
                            ) {
                              e.preventDefault();
                            }
                            return; // Combination key press or Enter key press, do nothing further
                          }

                          if (
                            charLimit &&
                            !e.ctrlKey &&
                            e.target?.value?.length + 1 > charLimit &&
                            e.key !== 'Backspace' &&
                            e.key !== 'Delete'
                          ) {
                            e.preventDefault();
                          }
                        }
                  }
                  onPaste={(event) => {
                    if (charLimit) {
                      const pastedText =
                        event.clipboardData.getData('text/plain');
                      const currentValue = event.currentTarget.value;
                      const selectionStart =
                        event.currentTarget.selectionStart || 0;
                      const selectionEnd =
                        event.currentTarget.selectionEnd || 0;
                      const beforeSelection = currentValue.slice(
                        0,
                        selectionStart,
                      );
                      const afterSelection = currentValue.slice(selectionEnd);

                      const newValue =
                        beforeSelection + pastedText + afterSelection;

                      if (newValue.length > charLimit) {
                        event.preventDefault();
                        const allowedText = pastedText.slice(
                          0,
                          charLimit -
                            beforeSelection.length -
                            afterSelection.length,
                        );
                        const updatedValue =
                          beforeSelection + allowedText + afterSelection;
                        event.currentTarget.value = updatedValue;
                        field.onChange({
                          ...event,
                          target: { ...event.target, value: updatedValue },
                        });
                      }
                    }
                  }}
                ></textarea>
              )}
            />
            {getValues && getValues(name) && (
              <div className="wordCount">
                <p>
                  {getValues(name).length}/ {charLimit || 500}
                </p>
              </div>
            )}
          </>
        ) : (
          <>
            <textarea
              name={name}
              id={id}
              rows={rows}
              value={value}
              defaultValue={defaultValue}
              disabled={disabled}
              placeholder={placeholder}
              onChange={onChange ? onChange : undefined}
              onKeyDown={
                onKeyDown
                  ? onKeyDown
                  : (e) => {
                      if (
                        e.ctrlKey ||
                        e.shiftKey ||
                        e.altKey ||
                        e.metaKey ||
                        e.key == 'Enter'
                      ) {
                        e.stopPropagation();
                        // If Enter key is pressed and character limit is exceeded, prevent default action
                        if (
                          e.key === 'Enter' &&
                          charLimit &&
                          e.target?.value?.length >= charLimit
                        ) {
                          e.preventDefault();
                        }
                        return; // Combination key press or Enter key press, do nothing further
                      }

                      if (
                        charLimit &&
                        !e.ctrlKey &&
                        e.target?.value?.length + 1 > charLimit &&
                        e.key !== 'Backspace' &&
                        e.key !== 'Delete'
                      ) {
                        e.preventDefault();
                      }
                    }
              }
              onPaste={(event) => {
                if (charLimit) {
                  const pastedText = event.clipboardData.getData('text/plain');
                  const currentValue = event.currentTarget.value;
                  const selectionStart =
                    event.currentTarget.selectionStart || 0;
                  const selectionEnd = event.currentTarget.selectionEnd || 0;
                  const beforeSelection = currentValue.slice(0, selectionStart);
                  const afterSelection = currentValue.slice(selectionEnd);

                  const newValue =
                    beforeSelection + pastedText + afterSelection;

                  if (newValue.length > charLimit) {
                    event.preventDefault();
                    const allowedText = pastedText.slice(
                      0,
                      charLimit -
                        beforeSelection.length -
                        afterSelection.length,
                    );
                    const updatedValue =
                      beforeSelection + allowedText + afterSelection;
                    event.currentTarget.value = updatedValue;
                    onChange &&
                      onChange({
                        ...event,
                        target: { ...event.target, value: updatedValue },
                      });
                  }
                }
              }}
            ></textarea>
            {value && (
              <div className="wordCount">
                <p>
                  {value.length}/ {charLimit || 500}
                </p>
              </div>
            )}
          </>
        )}

        <FormHelperText className={error ? 'Mui-error' : ''}>
          {helperText}
        </FormHelperText>
      </FormControl>
    </Box>
  );
};

Textarea.defaultProps = {
  placeholder: '',
  error: false,
  disabled: false,
};

export default Textarea;
