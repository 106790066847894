// EditDeliveryAddress.js
import React, { useEffect } from 'react';
import CustomButton from '../../../Components/CustomButton';
import InputField from '../../../Components/Input';
import AutocompleteSelect from '../../../Components/AutocompleteSelect';
import AddIcon from '@mui/icons-material/Add';
import { Dialog, Grid, IconButton } from '@mui/material';
import Images from '../../../Utils/images';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../redux/store';

interface Props {
  open: boolean;
  handleClose?: () => void;
  openAddAnotherAddressModal: () => void;
  selectedAddress?: any;
}

export default function EditDeliveryAddress(props: Props) {
  const { open, handleClose, openAddAnotherAddressModal, selectedAddress } =
    props;
  const { t: tC } = useTranslation('translation', {
    keyPrefix: 'Common',
  });
  const { t } = useTranslation('translation', {
    keyPrefix: 'orders.detail',
  });
  const { locationList } = useAppSelector((state) => state.order);

  const locationName =
    locationList && locationList.length > 0 ? locationList : [];

  return (
    <Dialog open={open} onClose={handleClose}>
      <div className="dialogWrapper warning orderAddress w__600">
        <div className="dialogHeader flex sps_between">
          <h4 className="small">{tC('edit_delivery_address')}</h4>
          <IconButton onClick={handleClose}>
            <img src={Images.CLOSE} alt={tC('close')} />
          </IconButton>
        </div>
        <div className="doalogBody small">
          <div className="formContainer">
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <InputField
                  requiredField
                  id="show_name"
                  label={t('show_name')}
                  name="show_name"
                  placeholder={t('enter_show_name')}
                  error={false}
                  helperText={''}
                  value={selectedAddress.productionName}
                  disabled
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  id="production_type"
                  label={t('production_type')}
                  name="production_type"
                  placeholder={t('enter_production_type')}
                  error={false}
                  helperText={''}
                  requiredField
                  value={selectedAddress.deliveryAddress?.productionType}
                  disabled
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  requiredField
                  id="set_name"
                  label={t('set_name')}
                  name="set_name"
                  placeholder={t('enter_set_name')}
                  error={false}
                  helperText={''}
                  value={selectedAddress.deliveryAddress?.setName}
                  disabled
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  id="episode"
                  label={t('episode')}
                  name="episode"
                  placeholder={t('enter_episode')}
                  error={false}
                  helperText={''}
                  requiredField
                  value={selectedAddress.deliveryAddress?.episode}
                  disabled
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  id="apartment_suite"
                  label={t('apartment_suite')}
                  name="apartment_suite"
                  placeholder={t('enter_apartment_suite')}
                  error={false}
                  helperText={''}
                  requiredField
                  value={selectedAddress.deliveryAddress?.apartment}
                  disabled
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputField
                  id="building_name"
                  label={t('building_name')}
                  name="building_name"
                  placeholder={t('enter_building_name')}
                  error={false}
                  helperText={''}
                  requiredField
                  value={selectedAddress.deliveryAddress?.buildingName}
                  disabled
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <AutocompleteSelect
                  id="address"
                  // control={control}
                  options={locationName}
                  labelText={tC('address')}
                  placeHolderText={tC('enter_address')}
                  name="type"
                  // setValue={setValue}
                  value={''}
                  onChange={(e: any) => {}}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <Grid item md={12} xs={12}>
          <CustomButton
            size="large"
            variant="text"
            text={t('add_another_address')}
            showIcon={true}
            width="auto"
            type="submit"
            id="chat_now"
            iconPosition="start"
            icon={<AddIcon />}
            iconType="svg"
            onClick={openAddAnotherAddressModal}
          />
        </Grid>
        <div className="dialogActions flex end">
          <CustomButton
            size="large"
            variant="outlined"
            text={tC('btnTxt.cancel')}
            showIcon={false}
            width="auto"
            type="submit"
            id="accept"
            loading={false}
            onClick={handleClose}
          />
          <CustomButton
            size="large"
            variant="contained"
            text={tC('btnTxt.save')}
            showIcon={false}
            width="auto"
            type="submit"
            id="accept"
            loading={false}
            onClick={handleClose}
          />
        </div>
      </div>
    </Dialog>
  );
}
