import * as yup from 'yup';
import { tranlate } from '../LocalizationJson/i18';
import { ErrorMsg, MaxFileSizeLimit } from '../helpers/contants';
import {
  ADDRESS_TYPES,
  EXTEND_PRICE_MODELS,
  HOLIDAY_TYPES,
  PACKAGE_TYPES,
  PRICE_MODE_TYPES,
  PRODUCTION_TYPE,
  PRODUCT_TABS,
  PRODUCT_TYPES,
  nameRegex,
} from './constantData';

const t = (k: string) => tranlate(k);

export const regexForZipcode = /^[A-Za-z0-9\s-]+$/;

// export const emailRegex =
//   /^[a-zA-Z0-9_+]+(\.[a-zA-Z0-9_+]+)*@[a-zA-Z0-9_+]+(\.[a-zA-Z0-9_+]+)+$/;
export const emailRegex =
  /^[a-zA-Z0-9_+]+(\.[a-zA-Z0-9_+]+)*@[a-zA-Z0-9_+]+\.[a-zA-Z]+(\.[a-zA-Z]+)*$/;

export const passwordRegex =
  /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>[\]]).{1,}$/;

const FirstNameSchema = yup.object({
  firstName: yup
    .string()
    .required(ErrorMsg(t('on_boarding.register.first_name')).required)
    .min(3, ErrorMsg(3).min)
    .max(25, ErrorMsg(25).max)
    .matches(
      nameRegex,
      ErrorMsg(t('on_boarding.register.first_name')).onlyLetter,
    ),
});

const LastNameSchema = yup.object({
  lastName: yup
    .string()
    .required(ErrorMsg(t('on_boarding.register.last_name')).required)
    .min(3, ErrorMsg(3).min)
    .max(25, ErrorMsg(25).max)
    .matches(
      nameRegex,
      ErrorMsg(t('on_boarding.register.last_name')).onlyLetter,
    ),
});

const MiddleNameSchema = yup.object({
  middleName: yup
    .string()
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value;
    })
    .matches(
      nameRegex,
      ErrorMsg(t('vendor_registration.contact_info.middle_name')).onlyLetter,
    )
    .min(3, ErrorMsg(3).min)
    .max(25, ErrorMsg(25).max)
    .nullable(),
});

export const SetPasswordSchema = yup.object({
  password: yup
    .string()
    .required(ErrorMsg(t('Common.password')).required)
    .min(8, ErrorMsg(t('Common.password'), 8).minCharLimit)
    .max(128, ErrorMsg(t('Common.password'), 128).maxCharLimit)
    .matches(passwordRegex, ErrorMsg().passwordError),
  confirmPassword: yup
    .string()
    .required(ErrorMsg(t('on_boarding.register.confirm_password')).required)
    .oneOf([yup.ref('password'), ''], t('validation_msgs.passMatch')),
});

const AttributesSchema = yup.object({
  attributes: yup
    .array()
    .of(
      yup.object().shape({
        key: yup
          .string()
          .required(ErrorMsg('Attribute name').required)
          .max(30, ErrorMsg(30).max),
        value: yup
          .string()
          .required(ErrorMsg('Description').required)
          .max(30, ErrorMsg(30).max),
      }),
    )
    .test(
      'atLeastOneValue',
      'At least one value is required',
      function (value) {
        if (Array.isArray(value)) {
          return value.length > 0;
        }
        // Handle other types if needed
        return false;
      },
    ),
});

export const TagsSchema = yup.object({
  tags: yup
    .array()
    .of(
      yup.object().shape({
        tag: yup
          .string()
          .required('String is required')
          .max(20, 'String must have at most 20 characters'),
      }),
    )
    .min(1, 'At least one tag is required')
    .max(3, 'Maximum of three tags allowed'),
  cardTags: yup
    .array()
    .of(
      yup.object().shape({
        tag: yup
          .string()
          .required('String is required')
          .max(20, 'String must have at most 20 characters'),
      }),
    )
    .min(1, 'At least one tag is required')
    .max(3, 'Maximum of three tags allowed'),
});

export const LoginFormSchema = yup
  .object({
    email: yup
      .string()
      .email('Invalid email address') // Checks if the string is a valid email format
      .max(50, 'Email must be 50 characters or less') // Ensures the email is no more than 50 characters long
      .required('Email is required')
      .matches(emailRegex, ErrorMsg('Email').valid), // Makes the email field required,

    password: yup
      .string()
      .required(ErrorMsg('Password').required)
      .min(8, ErrorMsg('Password', 8).minCharLimit)
      .max(128, ErrorMsg('Password', 128).maxCharLimit)
      .matches(passwordRegex, ErrorMsg().passwordError),
    rememberMe: yup.boolean().default(false),
  })
  .required();
export const forgotPasswordSchema = yup
  .object({
    email: yup
      .string()
      .required('Email is required.')
      .matches(emailRegex, 'Please enter a valid email.')
      .max(100, 'Email must be at most 100 characters long.')
      .email('Please enter a valid email.'),
  })
  .required();
export const RegisterFormSchema = yup
  .object({
    email: yup
      .string()
      .required(ErrorMsg('Email').required)
      .matches(emailRegex, ErrorMsg('email').valid)
      .max(50, ErrorMsg('Email', 50).maxCharLimit)
      .email(ErrorMsg('email').valid),
    termsAndConditions: yup.boolean().oneOf([true], ErrorMsg().terms),
  })
  .concat(FirstNameSchema)
  .concat(LastNameSchema)
  .concat(SetPasswordSchema)
  .required();

export const OtpSchema = yup
  .object({
    otpPart1: yup
      .string()
      .matches(/^\d{1}$/, ErrorMsg('digit').valid)
      .required(ErrorMsg('Digit').required),
    otpPart2: yup
      .string()
      .matches(/^\d{1}$/, ErrorMsg('digit').valid)
      .required(ErrorMsg('Digit').required),
    otpPart3: yup
      .string()
      .matches(/^\d{1}$/, ErrorMsg('digit').valid)
      .required(ErrorMsg('Digit').required),
    otpPart4: yup
      .string()
      .matches(/^\d{1}$/, ErrorMsg('digit').valid)
      .required(ErrorMsg('Digit').required),
    otpPart5: yup
      .string()
      .matches(/^\d{1}$/, ErrorMsg('digit').valid)
      .required(ErrorMsg('Digit').required),
    otpPart6: yup
      .string()
      .matches(/^\d{1}$/, ErrorMsg('digit').valid)
      .required(ErrorMsg('Digit').required),
  })
  .required();

export const numberValidation = (nullable: boolean = false) => {
  if (nullable) {
    return yup
      .number()
      .typeError(ErrorMsg('number').valid) // Display custom error message for non-numeric values
      .test('is-decimal', ErrorMsg().twoDecimalPoints, (value: any) => {
        if (!value) return true; // Allow empty string or undefined
        // Check if the value is a valid number with up to two decimal places
        return /^\d+(\.\d{0,2})?$/.test(value);
      })
      .min(0, 'Value must be 0 or positive')
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value;
      })
      .nullable();
  } else {
    return yup
      .number()
      .typeError(ErrorMsg('number').valid) // Display custom error message for non-numeric values
      .test(
        'is-decimal',
        'Enter a valid number with up to two decimal places',
        (value: any) => {
          if (!value) return true; // Allow empty string or undefined

          // Check if the value is a valid number with up to two decimal places
          return /^\d+(\.\d{0,2})?$/.test(value);
        },
      )
      .min(0, 'Value must be 0 or positive'); // Optional: Ensure the number is positive
  }
};

export const nullableStringValidation = yup
  .string()
  .transform((value, originalValue) => {
    return originalValue === '' ? null : value;
  })
  .nullable();

export const RentPricingSchema = yup.object({
  rentPricing: yup
    .array()
    .of(
      yup.object().shape({
        shiftLength: numberValidation(true)
          .integer(ErrorMsg().integer)
          .when('$type', {
            is: (val: string) => {
              return val === 'Service' || val === undefined;
            },
            then: (schema) =>
              schema.required(ErrorMsg('Shift Length').required),
          })
          .min(1, 'Please enter value between 1 to 16')
          .max(16, 'Please enter value between 1 to 16'),
        rentPrice: numberValidation(false).required(ErrorMsg('Price').required),
        minRateModel: yup
          .string()
          .typeError(ErrorMsg('Minimum Rate Model').required)
          .required(ErrorMsg('Minimum Rate Model').required),
        minDuration: numberValidation(true)
          .integer(ErrorMsg().integer)
          .required(ErrorMsg('Minimum rate model value').required),
        minShiftLength: yup
          .number()
          .when('type', {
            is: (val: string) => val === 'Service' || val === undefined,
            then: (schema) =>
              schema.required(ErrorMsg('Minimum Shift Length').required),
          })
          .typeError(ErrorMsg('Minimum Shift Length').required) // Display custom error message for non-numeric values
          .test('is-decimal', ErrorMsg().twoDecimalPoints, (value: any) => {
            if (!value) return true; // Allow empty string or undefined
            // Check if the value is a valid number with up to two decimal places
            return /^\d+(\.\d{0,2})?$/.test(value);
          })
          .min(0, 'Value must be 0 or positive')
          .integer(ErrorMsg().integer)
          .test(
            'minShiftLengthLessThanShiftLength',
            t('Validation_Messages.minShiftLengthLessThanShiftLength'),
            function (minShiftLength) {
              const shiftLength = this.parent.shiftLength;
              return (
                minShiftLength === null ||
                minShiftLength === undefined ||
                minShiftLength < shiftLength
              );
            },
          )
          .typeError(ErrorMsg('Minimum Shift Length').required),
        OTStartTime: numberValidation(true)
          .integer(ErrorMsg().integer)
          .test(
            'OTStartTimeGreaterThanShiftLength',
            'OT Start time must subceed shift duration.',
            function (OTStartTime) {
              const shiftLength = this.parent.shiftLength;
              return (
                OTStartTime === null ||
                OTStartTime === undefined ||
                OTStartTime >= shiftLength
              );
            },
          )
          .test(
            'OTStartTimeNotSameWithDT',
            'OT and DT start time cannot be same.',
            function (OTStartTime) {
              const DTStartTime = this.parent.DTStartTime;
              return (
                OTStartTime === null ||
                OTStartTime === undefined ||
                OTStartTime !== DTStartTime
              );
            },
          ),
        DTStartTime: numberValidation(true)
          .integer(ErrorMsg().integer)
          .test(
            'DTStartTimeGreaterThanShiftLength',
            'DT Start time must subceed shift duration.',
            function (DTStartTime) {
              const shiftLength = this.parent.shiftLength;
              return (
                DTStartTime === null ||
                DTStartTime === undefined ||
                DTStartTime >= shiftLength
              );
            },
          )
          .test(
            'DTStartTimeNotSameWithOT',
            'OT and DT start time cannot be same.',
            function (DTStartTime) {
              const OTStartTime = this.parent.OTStartTime;
              return (
                DTStartTime === null ||
                DTStartTime === undefined ||
                DTStartTime !== OTStartTime
              );
            },
          )
          .test(
            'DTStartTimeNotSameWithOT',
            'DT start time must exceed OT time.',
            function (DTStartTime) {
              const OTStartTime = this.parent.OTStartTime;
              return (
                DTStartTime === null ||
                DTStartTime === undefined ||
                DTStartTime > OTStartTime
              );
            },
          ),
      }),
    )
    .when('type', {
      is: (val: string) => {
        return (
          val === PRODUCT_TYPES.rental || val === PRODUCT_TYPES.rentAndPurchase
        );
      },
      then: (schema) => {
        return schema.required(ErrorMsg('Rate Model').required);
      },
    }),
});

export const RentProductPricingSchema = yup.object({
  rentPricing: yup
    .array()
    .of(
      yup.object().shape({
        baseRate: numberValidation(true).when('extendedPriceMode', {
          is: (val: string) => {
            return val === EXTEND_PRICE_MODELS.BASE_RATE;
          },
          then: (schema) => schema.required(ErrorMsg('Base rate').required),
        }),
        extendedPriceMode: yup.string().default('MINIMUM_RATE'),
        rentPrice: numberValidation(false).required(ErrorMsg('Price').required),
        minRateModel: yup
          .string()
          .typeError(ErrorMsg('Minimum Rate Model').required)
          .required(ErrorMsg('Minimum Rate Model').required),
        minDuration: numberValidation(true)
          .integer(ErrorMsg().integer)
          .required(ErrorMsg('Minimum rate model value').required),
      }),
    )
    .when('type', {
      is: (val: string) => {
        return (
          val === PRODUCT_TYPES.rental || val === PRODUCT_TYPES.rentAndPurchase
        );
      },
      then: (schema) => {
        return schema.required(ErrorMsg('Rate Model').required);
      },
    }),
});

export const NewServiceRentPricingSchema = yup.object({
  rentPricing: yup.array().of(
    yup.object().shape({
      priceMode: yup.string(),
      shiftLength: numberValidation(true)
        .integer(ErrorMsg().integer)
        .when('priceMode', {
          is: (val: string) => {
            return val === PRICE_MODE_TYPES.PER_HOUR;
          },
          then: (schema) =>
            schema
              .required(ErrorMsg('Shift Length').required)
              .min(1, 'Please enter value between 1 to 16')
              .max(16, 'Please enter value between 1 to 16'),
        }),
      rentPrice: numberValidation(false).required(ErrorMsg('Price').required),
      minRateModel: yup
        .string()
        .typeError(ErrorMsg('Minimum Rate Model').required)
        .required(ErrorMsg('Minimum Rate Model').required),
      minDuration: numberValidation(true)
        .integer(ErrorMsg().integer)
        .required(ErrorMsg('Minimum rate model value').required),
      minShiftLength: yup
        .number()
        .typeError(ErrorMsg('Minimum Shift Length').required)
        .when('priceMode', {
          is: (val: string) => {
            return val === PRICE_MODE_TYPES.PER_HOUR;
          },
          then: (schema) =>
            schema
              .required(ErrorMsg('Minimum Shift Length').required)
              .test('is-decimal', ErrorMsg().twoDecimalPoints, (value: any) => {
                if (!value) return true; // Allow empty string or undefined
                // Check if the value is a valid number with up to two decimal places
                return /^\d+(\.\d{0,2})?$/.test(value);
              })
              .min(0, 'Value must be 0 or positive')
              .integer(ErrorMsg().integer)
              .test(
                'minShiftLengthLessThanShiftLength',
                t('Validation_Messages.minShiftLengthLessThanShiftLength'),
                function (minShiftLength) {
                  const shiftLength = this.parent.shiftLength;
                  return (
                    minShiftLength === null ||
                    minShiftLength === undefined ||
                    minShiftLength < shiftLength
                  );
                },
              ),
        }),
      OTStartTime: numberValidation(true)
        .integer(ErrorMsg().integer)
        .test(
          'OTStartTimeGreaterThanShiftLength',
          'OT Start time must subceed shift duration.',
          function (OTStartTime) {
            const shiftLength = this.parent.shiftLength;
            return (
              OTStartTime === null ||
              OTStartTime === undefined ||
              OTStartTime >= shiftLength
            );
          },
        )
        .test(
          'OTStartTimeNotSameWithDT',
          'OT and DT start time cannot be same.',
          function (OTStartTime) {
            const DTStartTime = this.parent.DTStartTime;
            return (
              OTStartTime === null ||
              OTStartTime === undefined ||
              OTStartTime !== DTStartTime
            );
          },
        ),
      DTStartTime: numberValidation(true)
        .integer(ErrorMsg().integer)
        .test(
          'DTStartTimeGreaterThanShiftLength',
          'DT Start time must subceed shift duration.',
          function (DTStartTime) {
            const shiftLength = this.parent.shiftLength;
            return (
              DTStartTime === null ||
              DTStartTime === undefined ||
              DTStartTime >= shiftLength
            );
          },
        )
        .test(
          'DTStartTimeNotSameWithOT',
          'OT and DT start time cannot be same.',
          function (DTStartTime) {
            const OTStartTime = this.parent.OTStartTime;
            return (
              DTStartTime === null ||
              DTStartTime === undefined ||
              DTStartTime !== OTStartTime
            );
          },
        )
        .test(
          'DTStartTimeNotSameWithOT',
          'DT start time must exceed OT time.',
          function (DTStartTime) {
            const OTStartTime = this.parent.OTStartTime;
            return (
              DTStartTime === null ||
              DTStartTime === undefined ||
              DTStartTime > OTStartTime
            );
          },
        ),
      baseRate: numberValidation(true).when('extendedPriceMode', {
        is: (val: string) => {
          return val === EXTEND_PRICE_MODELS.BASE_RATE;
        },
        then: (schema) => schema.required(ErrorMsg('Base rate').required),
      }),
      extendedPriceMode: yup.string(),
    }),
  ),
});

export const FormCommonSchema = yup.object({
  department: yup
    .object({
      _id: yup.string(),
      deptName: yup.string(),
    })
    .required(ErrorMsg('Department').required),
  // .min(1, 'At least one department is required')
  // .default([])
  category: yup
    .object({
      _id: yup.string(),
      deptName: yup.string(),
    })
    .required(ErrorMsg('Category').required),
  isFeatureProduct: yup.boolean().default(false),
  title: yup
    .string()
    .required(ErrorMsg('Title').required)
    .min(3, ErrorMsg(3).min)
    .max(50, ErrorMsg(50).max),
  eta: numberValidation(true)
    .integer(ErrorMsg().integer)
    .when('type', {
      is: (val: string) => val === PRODUCT_TYPES.purchase,
      then: (schema) => schema.required(ErrorMsg('Eta').required),
    }),
  // .required(ErrorMsg('Eta').required),

  deliveryNote: yup.string().max(500, ErrorMsg(500).max),
  // .required(ErrorMsg('Delivery note').required)
  isRushEnable: yup.boolean().default(false),
  isHolidayPay: yup.boolean().default(false),
  rushFee: yup
    .number()
    .when('isRushEnable', {
      is: (val: boolean) => val,
      then: (schema) => schema.required(ErrorMsg('Rush Fee').required),
    })
    .typeError(ErrorMsg('Rush Fee').required) // Display custom error message for non-numeric values
    .test(
      'is-decimal',
      'Enter a valid number with up to two decimal places',
      (value: any) => {
        if (!value) return true; // Allow empty string or undefined

        // Check if the value is a valid number with up to two decimal places
        return /^\d+(\.\d{0,2})?$/.test(value);
      },
    )
    .min(0, 'Value must be 0 or positive')
    .test(
      'RushFeeLessThanEta',
      'Rush fee should be greater than delivery charges.',
      function (RushFee) {
        const fee =
          this.parent.type === 'Service' ||
          this.parent.packageType === PACKAGE_TYPES[1].value ||
          this.parent.packageType === PACKAGE_TYPES[2].value
            ? this.parent.rentDeliveryFee
            : this.parent.deliveryFee;

        if (fee === undefined) return true;
        return RushFee === null || RushFee === undefined || RushFee > fee;
      },
    ),
  rushEta: numberValidation(true)
    .integer(ErrorMsg().integer)
    .when('isRushEnable', {
      is: (val: boolean) => val,
      then: (schema) => schema.required(ErrorMsg('Rush Eta').required),
    })
    .test(
      'RushEtaLessThanEta',
      'Rush ETA should be less than expected ETA.',
      function (RushEta) {
        const eta = this.parent.eta;
        if (eta === undefined) return true;
        return RushEta === null || RushEta === undefined || RushEta < eta;
      },
    ),
  securityDepositExist: yup.boolean().default(false),
  depositPercentage: numberValidation(true)
    .min(1, 'Please enter value from 1 to 40')
    .max(40, 'Please enter value from 1 to 40')
    .when('securityDepositExist', {
      is: (val: boolean) => val,
      then: (schema) =>
        schema.required(ErrorMsg('Deposit percentage').required),
    }),
  sequenceNo: yup.string().nullable().default(null),
  mileageRate: numberValidation(true),
  // .required(
  //   ErrorMsg('Mileage Rate').required
  // ), Making non required for now as per https://appinventivtech.atlassian.net/browse/REEL-2288
});

// export const VariantsSchema = yup.object({
//   isVariantPresent: yup.boolean().default(false),
//   name: yup
//     .object()
//     .when('isVariantPresent', {
//       is: (val: string) => val,
//       then: (schema) => schema.required(ErrorMsg('Name').required),
//     })
//     .nullable(),
//   value: yup
//     .object()
//     .when('isVariantPresent', {
//       is: (val: string) => val,
//       then: (schema) => schema.required(ErrorMsg('Value').required),
//     })
//     .nullable(),
//   productName: yup
//     .object()
//     .when('isVariantPresent', {
//       is: (val: string) => val,
//       then: (schema) => schema.required(ErrorMsg('ProductName').required),
//     })
//     .nullable(),
// });
yup.addMethod(yup.array, 'uniqueNames', function (message) {
  return this.test('unique', message, function (array) {
    const nameCounts = array?.reduce((acc, item, index) => {
      if (item.name in acc) {
        acc[item.name].count++;
        acc[item.name].firstIndex =
          acc[item.name].firstIndex !== -1 ? acc[item.name].firstIndex : index;
      } else {
        acc[item.name] = { count: 1, firstIndex: -1 };
      }
      return acc;
    }, {});

    for (let key in nameCounts) {
      if (nameCounts[key].count > 1) {
        return this.createError({
          path: `${this.path}[${
            nameCounts[key].firstIndex
          }].values[${0}].propertyTitle`,
          message: 'The property name should be unique.',
        });
      }
    }
    return true;
  });
});

// Custom method to check for duplicate propertyValue within each values array
yup.addMethod(yup.array, 'uniquePropertyValues', function (message) {
  return this.test(
    'uniquePropertyValues',
    message,
    function (valuesArray: any) {
      const propertyValueCounts = valuesArray.reduce(
        (acc: any, value: any, index: any) => {
          // Skip objects where variantProductId is present
          if (value.variantProductId) {
            return acc;
          }

          if (value.propertyValue in acc) {
            acc[value.propertyValue].count++;
            acc[value.propertyValue].firstIndex =
              acc[value.propertyValue].firstIndex !== -1
                ? acc[value.propertyValue].firstIndex
                : index;
          } else {
            acc[value.propertyValue] = { count: 1, firstIndex: -1 };
          }
          return acc;
        },
        {},
      );

      for (let key in propertyValueCounts) {
        if (propertyValueCounts[key].count > 1) {
          return this.createError({
            path: `${this.path}[${propertyValueCounts[key].firstIndex}].propertyValue`,
            message: 'The property value should be unique.',
          });
        }
      }
      return true;
    },
  );
});

const VariantsSchema = yup.object({
  isVariantProduct: yup.boolean().default(false),
  variantProducts: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string(),
        values: yup
          .array()
          .of(
            yup.object().shape({
              propertyTitle: yup.string().required('Name is required'),
              propertyValue: yup.string().required('Value is required'),
              // productId: yup.object().required(ErrorMsg('Product').required),
              productId: yup
                .object()
                .required('Product is required')
                .test('is-not-empty', 'Product is required', (value: any) => {
                  return value && value?.title !== '';
                }),
            }),
          )
          .uniquePropertyValues(
            'Duplicate propertyValue found within this values array',
          )
          .min(1, 'At least one value is required'),
      }),
    )
    .uniqueNames('Duplicate name found')
    .when('isVariantProduct', {
      is: (val: string) => {
        return val;
      },
      then: (schema) => {
        return schema.required(ErrorMsg('Variant Model').required);
      },
    }),
});

const VariantsServiceSchema = yup.object({
  isVariantProduct: yup.boolean().default(false),
  variantProducts: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string(),
        values: yup
          .array()
          .of(
            yup.object().shape({
              propertyTitle: yup.string().required('Name is required'),
              propertyValue: yup.string().required('Value is required'),
              productId: yup
                .object()
                // .required('Product is required')
                .test('is-not-empty', 'Service is required', (value: any) => {
                  return value && value?.title !== '';
                }),
            }),
          )
          .uniquePropertyValues(
            'Duplicate propertyValue found within this values array',
          )

          .min(1, 'At least one value is required'),
      }),
    )
    .uniqueNames('Duplicate name found')
    .when('isVariantProduct', {
      is: (val: string) => {
        return val;
      },
      then: (schema) => {
        return schema.required(ErrorMsg('Variant Model').required);
      },
    }),
});

//   isVariantProduct: yup.boolean().default(true),
//   variantProducts: yup
//     .array()
//     .of(
//       yup.object().shape({
//         name: yup.string(),
//         values: yup
//           .array()
//           .of(
//             yup.object().shape({
//               propertyTitle: yup.string().required('Name is required'),
//               propertyValue: yup.string().required('Value is required'),
//               productId: yup
//                 .object()
//                 .required('Product id is required')
//                 .test(
//                   'is-not-empty',
//                   'Product id is required',
//                   (value: any) => {
//                     return value && value?.title !== '';
//                   }
//                 ),
//             })
//           )
//           .min(1, 'At least one value is required')
//           .test(
//             'unique-property-value',
//             'Duplicate property value found',
//             function (values) {
//               const seen = new Set();
//               for (const value of values) {
//                 if (seen.has(value.propertyValue)) {
//                   return false;
//                 }
//                 seen.add(value.propertyValue);
//               }
//               return true;
//             }
//           ),
//       })
//     )
//     .when('isVariantProduct', {
//       is: (val: string) => {
//         return val;
//       },
//       then: (schema) => {
//         return schema.required('Variant Model is required');
//       },
//     }),
// });

export type AddProductFormInterface = yup.InferType<
  typeof AddProductFormSchema
> &
  yup.InferType<typeof FormCommonSchema>;

export const AddProductFormSchema = yup
  .object({
    productTaxCode: yup
      .object()
      .when('type', {
        is: (val: string) =>
          val === PRODUCT_TYPES.purchase ||
          val === PRODUCT_TYPES.rentAndPurchase,
        then: (schema) =>
          schema.required(ErrorMsg('Product Tax Category').required),
      })
      .nullable(),
    type: yup.string().required('Please select type.'),
    productModel: nullableStringValidation
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).max),
    sku: nullableStringValidation
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).max)
      .matches(/^\S*$/, 'Invalid SKU code')
      .required(ErrorMsg('Sku').required),
    // .default(1), // Optional: Ensure the number is an integer,
    description: yup.string().max(5000, ErrorMsg(5000).max),
    price: numberValidation(false).when('type', {
      is: (val: string) =>
        val === PRODUCT_TYPES.purchase || val === PRODUCT_TYPES.rentAndPurchase,
      then: (schema) => schema.required(ErrorMsg('Price').required),
    }),

    // perDiemCost: numberValidation(true),
    // travelCost: numberValidation(true),
    cancellationFee: numberValidation(true),
    deliveryFee: numberValidation(true),
    // .when('deliveryType', {
    //   is: (val: string) => val === 'Drop-offs',
    //   then: (schema) => schema.required(ErrorMsg('Delivery Fee').required),
    // })
    // .when('type', {
    //   is: (val: string) => val === PRODUCT_TYPES.rental,
    //   then: (schema) => schema.notRequired(),
    // }),
    deliveryType: yup
      .string()
      .required(ErrorMsg('Delivery Type').required)
      .when('type', {
        is: (val: string) => val === PRODUCT_TYPES.rental,
        then: (schema) => schema.notRequired(),
      })
      .default('Drop-offs'),
    discountType: yup.string().default('%'),
    discount: numberValidation(true).when('discountType', {
      is: (val: string) => val === '%',
      then: (schema) => schema.max(100, ErrorMsg('').percentage),
    }),
    files: yup
      .mixed()
      // .test(
      //   'fileSize',
      //   'Total files should be less than 35MB',
      //   (value: any) => {
      //     if (!value) return true;
      //     const totalSize: any = Array.from(value).reduce(
      //       (acc, file: any) => acc + file.size,
      //       0
      //     );
      //     return totalSize <= 36700160; // 35MB in bytes
      //   }
      // )
      .test('fileType', 'Invalid file type', (value: any) => {
        if (!value) return true;

        const imageCount = Array.from(value).filter(
          (file: any) =>
            file.type === 'image/jpeg' ||
            file.type === 'image/jpg' ||
            file.type === 'image/png',
        ).length;

        const videoCount = Array.from(value).filter(
          (file: any) => file.type === 'video/mp4',
        ).length;

        return imageCount + videoCount === Array.from(value).length;
      })
      .test(
        'imageSize',
        'Each image should be less than 10MB',
        (value: any) => {
          if (!value) return true;
          return Array.from(value).every(
            (file: any) =>
              file.type.startsWith('video/')
                ? true
                : file.type.startsWith('image/') && file.size <= 10485760, // 10MB in bytes
          );
        },
      )
      .test(
        'videoSize',
        'Each video should be less than 50MB',
        (value: any) => {
          if (!value) return true;
          return Array.from(value).every(
            (file: any) =>
              file.type.startsWith('image/')
                ? true
                : file.type.startsWith('video/') && file.size <= 52428800, // 50MB in bytes 48060415
          );
        },
      ),
    rentPerDiemCost: numberValidation(true),
    rentTravelCost: numberValidation(true),
    rentCancellationFee: numberValidation(true),
    rentDeliveryType: yup.string(),
    rentDeliveryFee: numberValidation(true),
    // .when('rentDeliveryType', {
    //   is: (val: string) => val === 'Drop-offs',
    //   then: (schema) => schema.required(ErrorMsg('Delivery Fee').required),
    // })
    // .when('type', {
    //   is: (val: string) => val === PRODUCT_TYPES.purchase,
    //   then: (schema) => schema.notRequired(),
    // }),
    rentDiscountType: yup.string().default('%'),
    rentDiscount: numberValidation(true).when('rentDiscountType', {
      is: (val: string) => val === '%',
      then: (schema) => schema.max(100, ErrorMsg('').percentage),
    }),
  })
  .concat(AttributesSchema)
  .concat(RentProductPricingSchema)
  .concat(FormCommonSchema)
  .concat(VariantsSchema)
  .required();

export const AddProductVariantFormSchema = yup
  .object({
    // productTaxCode: yup
    //   .object()
    //   .required(ErrorMsg('Product Tax Category').required),
    type: yup.string().required('Please select type.'),
    productModel: nullableStringValidation
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).max),
    sku: nullableStringValidation
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).max)
      .matches(/^\S*$/, 'Invalid SKU code')
      .required(ErrorMsg('Sku').required),
    // .default(1), // Optional: Ensure the number is an integer,
    description: yup.string().max(5000, ErrorMsg(5000).max),
    price: numberValidation(false).when('type', {
      is: (val: string) =>
        val === PRODUCT_TYPES.purchase || val === PRODUCT_TYPES.rentAndPurchase,
      then: (schema) => schema.required(ErrorMsg('Price').required),
    }),

    perDiemCost: numberValidation(true),
    travelCost: numberValidation(true),
    cancellationFee: numberValidation(true),
    deliveryFee: numberValidation(false)
      .when('deliveryType', {
        is: (val: string) => val === 'Drop-offs',
        then: (schema) => schema.required(ErrorMsg('Delivery Fee').required),
      })
      .when('type', {
        is: (val: string) => val === PRODUCT_TYPES.rental,
        then: (schema) => schema.notRequired(),
      }),
    deliveryType: yup
      .string()
      .required(ErrorMsg('Delivery Type').required)
      .when('type', {
        is: (val: string) => val === PRODUCT_TYPES.rental,
        then: (schema) => schema.notRequired(),
      })
      .default('Drop-offs'),
    discountType: yup.string().default('%'),
    discount: numberValidation(true).when('discountType', {
      is: (val: string) => val === '%',
      then: (schema) => schema.max(100, ErrorMsg('').percentage),
    }),
    files: yup
      .mixed()
      .test(
        'fileSize',
        'Total files should be less than 35MB',
        (value: any) => {
          if (!value) return true;
          const totalSize: any = Array.from(value).reduce(
            (acc, file: any) => acc + file.size,
            0,
          );
          return totalSize <= 36700160;
        },
      )
      .test('fileType', 'Invalid file type', (value: any) => {
        if (!value) return true;

        const imageCount = Array.from(value).filter(
          (file: any) =>
            file.type === 'image/jpeg' ||
            file.type === 'image/jpg' ||
            file.type === 'image/png',
        ).length;

        const videoCount = Array.from(value).filter(
          (file: any) => file.type === 'video/mp4',
        ).length;

        return imageCount + videoCount === Array.from(value).length;
      })
      .test('imageSize', 'Each image should be less than 3MB', (value: any) => {
        if (!value) return true;
        return Array.from(value).every((file: any) =>
          file.type.startsWith('video/')
            ? true
            : file.type.startsWith('image/') && file.size <= 3145728,
        ); // 3MB in bytes
      })
      .test('videoSize', 'Video should be less than 30MB', (value: any) => {
        if (!value) return true;
        return Array.from(value).every((file: any) =>
          file.type.startsWith('image/')
            ? true
            : file.type.startsWith('video/') && file.size <= 31457280,
        );
      }),
    // rentPerDiemCost: numberValidation(true),
    // rentTravelCost: numberValidation(true),
    rentCancellationFee: numberValidation(true),
    rentDeliveryType: yup.string(),
    rentDeliveryFee: numberValidation(true),
    // .when('rentDeliveryType', {
    //   is: (val: string) => val === 'Drop-offs',
    //   then: (schema) => schema.required(ErrorMsg('Delivery Fee').required),
    // })
    // .when('type', {
    //   is: (val: string) => val === PRODUCT_TYPES.purchase,
    //   then: (schema) => schema.notRequired(),
    // }),
    rentDiscountType: yup.string().default('%'),
    rentDiscount: numberValidation(true).when('rentDiscountType', {
      is: (val: string) => val === '%',
      then: (schema) => schema.max(100, ErrorMsg('').percentage),
    }),
  })
  .concat(AttributesSchema)
  .concat(RentProductPricingSchema)
  // .concat(FormCommonSchema)
  .required();

export const AddPackageFormSchema = yup
  .object({
    packageType: yup.string().required(ErrorMsg('Package Type').required),
    products: yup.array().of(
      yup.object({
        product: yup.object().required(ErrorMsg('Product').required),
        qty: numberValidation(false).required(ErrorMsg('Quantity').required),
      }),
    ),
    brand: yup
      .string()
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).max)
      .nullable(),
    services: yup.array().of(
      yup.object({
        product: yup.object().required(ErrorMsg('Service').required),
        qty: numberValidation(false).required(ErrorMsg('Quantity').required),
      }),
    ),
    productTaxCode: yup
      .object()
      .when('packageType', {
        is: (val: string) => val !== PACKAGE_TYPES[1].value,
        then: (schema) =>
          schema.required(ErrorMsg('Product Tax Category').required),
      })
      .when('packageType', {
        is: (val: string) => val === PACKAGE_TYPES[1].value,
        then: (schema) => schema.nullable(),
      }),
    type: yup.string().when('packageType', {
      is: (val: string) => val === PACKAGE_TYPES[0].value,
      then: (schema) => schema.required('Please select type.'),
    }),
    productModel: nullableStringValidation
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).max),
    sku: nullableStringValidation
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).max)
      .matches(/^\S*$/, 'Invalid SKU code')
      .required(ErrorMsg('Sku').required),
    // .default(1), // Optional: Ensure the number is an integer,
    description: yup.string().max(5000, ErrorMsg(5000).max),
    price: numberValidation(false).when('type', {
      is: (val: string) =>
        val === PRODUCT_TYPES.purchase || val === PRODUCT_TYPES.rentAndPurchase,
      then: (schema) => schema.required(ErrorMsg('Price').required),
    }),
    // perDiemCost: numberValidation(true),
    // travelCost: numberValidation(true),
    cancellationFee: numberValidation(true),
    deliveryFee: numberValidation(true),
    // .when('deliveryType', {
    //   is: (val: string) => val === 'Drop-offs',
    //   then: (schema) => schema.required(ErrorMsg('Delivery Fee').required),
    // })
    // .when('type', {
    //   is: (val: string) => val === PRODUCT_TYPES.rental,
    //   then: (schema) => schema.notRequired(),
    // })
    // .when('packageType', {
    //   is: (val: string) => val !== PACKAGE_TYPES[0].value,
    //   then: (schema) => schema.notRequired(),
    // }),
    deliveryType: yup
      .string()
      // .required(ErrorMsg('Delivery Type').required)
      .when('type', {
        is: (val: string) => val === PRODUCT_TYPES.rental,
        then: (schema) => schema.notRequired(),
      })
      .default('Drop-offs'),
    discountType: yup.string().default('%'),
    discount: numberValidation(true).when('discountType', {
      is: (val: string) => val === '%',
      then: (schema) => schema.max(100, ErrorMsg('').percentage),
    }),
    files: yup
      .mixed()
      .test(
        'fileSize',
        'Total files should be less than 35MB',
        (value: any) => {
          if (!value) return true;
          const totalSize: any = Array.from(value).reduce(
            (acc, file: any) => acc + file.size,
            0,
          );
          return totalSize <= 36700160;
        },
      )
      .test('fileType', 'Invalid file type', (value: any) => {
        if (!value) return true;

        const imageCount = Array.from(value).filter(
          (file: any) =>
            file.type === 'image/jpeg' ||
            file.type === 'image/jpg' ||
            file.type === 'image/png',
        ).length;

        const videoCount = Array.from(value).filter(
          (file: any) => file.type === 'video/mp4',
        ).length;

        return imageCount + videoCount === Array.from(value).length;
      })
      .test('imageSize', 'Each image should be less than 3MB', (value: any) => {
        if (!value) return true;
        return Array.from(value).every((file: any) =>
          file.type.startsWith('video/')
            ? true
            : file.type.startsWith('image/') && file.size <= 3145728,
        ); // 3MB in bytes
      })
      .test('videoSize', 'Video should be less than 30MB', (value: any) => {
        if (!value) return true;
        return Array.from(value).every((file: any) =>
          file.type.startsWith('image/')
            ? true
            : file.type.startsWith('video/') && file.size <= 31457280,
        );
      }),
    // rentPerDiemCost: numberValidation(true),
    // rentTravelCost: numberValidation(true),
    rentCancellationFee: numberValidation(true),
    rentDeliveryType: yup.string(),
    rentDeliveryFee: numberValidation(true),
    // .when('rentDeliveryType', {
    //   is: (val: string) => val === 'Drop-offs',
    //   then: (schema) => schema.required(ErrorMsg('Delivery Fee').required),
    // })
    // .when('type', {
    //   is: (val: string) => val === PRODUCT_TYPES.purchase,
    //   then: (schema) => schema.notRequired(),
    // }),
    rentDiscountType: yup.string().default('%'),
    rentDiscount: numberValidation(true).when('rentDiscountType', {
      is: (val: string) => val === '%',
      then: (schema) => schema.max(100, ErrorMsg('').percentage),
    }),
  })
  .concat(AttributesSchema)
  .concat(NewServiceRentPricingSchema)
  .concat(FormCommonSchema)
  .when('type', {
    is: (val: string) => val === 'Service',
    then: (schema) => schema.concat(TagsSchema),
  })
  .required();

export type AddServiceFormInterface = yup.InferType<
  typeof AddServiceFormSchema
> &
  yup.InferType<typeof FormCommonSchema>;

export const AddServiceFormSchema = yup
  .object({
    type: yup.string().default('Service'),
    subTitle: yup.string().min(3, ErrorMsg(3).min).max(60, ErrorMsg(60).max),
    isFeatureProduct: yup.boolean().default(false),
    description: yup.string().max(5000, ErrorMsg(5000).max),
    files: yup
      .mixed()
      .test(
        'fileSize',
        'Total files should be less than 35MB',
        (value: any) => {
          if (!value) return true;
          const totalSize: any = Array.from(value).reduce(
            (acc, file: any) => acc + file.size,
            0,
          );
          return totalSize <= 36700160;
        },
      )
      .test('fileType', 'Invalid file type', (value: any) => {
        if (!value) return true;

        const imageCount = Array.from(value).filter(
          (file: any) =>
            file.type === 'image/jpeg' ||
            file.type === 'image/jpg' ||
            file.type === 'image/png',
        ).length;

        const videoCount = Array.from(value).filter(
          (file: any) => file.type === 'video/mp4',
        ).length;

        return imageCount + videoCount === Array.from(value).length;
      })
      .test('imageSize', 'Each image should be less than 3MB', (value: any) => {
        if (!value) return true;
        return Array.from(value).every((file: any) =>
          file.type.startsWith('video/')
            ? true
            : file.type.startsWith('image/') && file.size <= 3145728,
        ); // 3MB in bytes
      })
      .test('videoSize', 'Video should be less than 30MB', (value: any) => {
        if (!value) return true;
        return Array.from(value).every((file: any) =>
          file.type.startsWith('image/')
            ? true
            : file.type.startsWith('video/') && file.size <= 31457280,
        );
      }),
    rentPerDiemCost: numberValidation(true),
    rentTravelCost: numberValidation(true),
    rentCancellationFee: numberValidation(true),
    rentDeliveryFee: numberValidation(true),
    // .required(
    //   ErrorMsg('Rent Delivery Fee').required,
    // ),
    rentDiscountType: yup.string().default('%'),
    rentDiscount: numberValidation(true).when('rentDiscountType', {
      is: (val: string) => val === '%',
      then: (schema) => schema.max(100, ErrorMsg('').percentage),
    }),
  })
  .concat(AttributesSchema)
  .concat(NewServiceRentPricingSchema)
  .concat(FormCommonSchema)
  .concat(VariantsServiceSchema)
  .concat(TagsSchema)
  .required();

export const BusinessSchema = yup
  .object({
    businessLocation: yup
      .object()
      .typeError(ErrorMsg('Business Location').required)
      .required(ErrorMsg('Business Location').required),
    businessType: yup.string().required(ErrorMsg('Business Type').required),
    businessName: yup
      .string()
      .required(ErrorMsg('Business Name').required)
      // .matches(
      //   /^[a-zA-Z0-9_\s]+$/,
      //   'Value can only contain letters, numbers, and underscores'
      // )
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).max),
    department: yup
      .array()
      .min(1, 'Select at least one value')
      .required('Select at least one value'),
    businessAddress: yup.object().required(ErrorMsg('Address').required),
    // .max(500, 'Address must be at most 500 characters long'),
    businessZipCode: yup
      .string()
      .required(ErrorMsg('Zip code').required)
      .matches(regexForZipcode, 'Invalid Zip code.')
      .min(3, 'Invalid Zip code.')
      .max(9, 'Invalid Zip code.'),
    businessCity: yup.string().required(ErrorMsg('City').required),
    businessState: yup.string().required(ErrorMsg('State').required),
  })
  .required();

export const ContactInfoSchema = yup
  .object({
    // dob: yup
    //   .string()
    //   .test('validDateOfBirth', 'Invalid date of birth', function (value) {
    //     if (!value) return true // Allow empty input
    //     const today = new Date()
    //     const dob = new Date(value)
    //     const age = today.getFullYear() - dob.getFullYear()
    //     if (dob > today || age < 18) {
    //       return false
    //     }
    //     return true
    //   }),
    zipCode: yup
      .string()
      .matches(regexForZipcode, 'Invalid Zip code.')
      .min(3, 'Invalid Zip code.')
      .max(9, 'Invalid Zip code.'),
    city: yup.string(),
    state: yup.string(),
    country: yup.string(),
    addressLineOne: yup
      .string()
      .nullable()
      .max(500, 'Address must be at most 500 characters long'),
    addressLineTwo: yup
      .string()
      .max(500, 'Address must be at most 500 characters long'),
  })
  .concat(FirstNameSchema)
  .concat(LastNameSchema)
  .concat(MiddleNameSchema)
  .required();

export const LegalDocSchema = yup
  .object({
    documentType: yup.string().required(ErrorMsg('Document Type').required),
    document: yup
      .mixed()
      .test(
        'fileExtension',
        'Only png/jpg/jpeg/pdf/doc/docx file type allowed.',
        (value: any) => {
          if (!value) return true; // Allow empty input
          const supportedFileTypes = [
            'png',
            'jpg',
            'jpeg',
            'pdf',
            'doc',
            'docx',
            'msword',
          ];
          const fileName = value.name;
          if (fileName) {
            const fileExtension = fileName.slice(
              ((fileName.lastIndexOf('.') - 1) >>> 0) + 2,
            );
            return supportedFileTypes.includes(fileExtension.toLowerCase());
          } else {
            return false;
          }
        },
      )
      .test('fileSize', ErrorMsg('10').fileSizeError, (value: any) => {
        if (!value) return true; // Allow empty input
        return value.size <= MaxFileSizeLimit;
      })
      .required(ErrorMsg('Document').required),
    documentNumber: yup
      .string()
      .required(ErrorMsg('Document Number').required)
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).max)
      .matches(/^[a-zA-Z0-9]+$/, ErrorMsg().specialCharNotAlld),
    countryOfIssue: yup
      .object()
      .required(ErrorMsg('Country of issue').required),
    dateOfExp: yup
      .date()
      .typeError('Invalid date')
      .required(ErrorMsg('Date of expire').required)
      .min(new Date(), ErrorMsg().futureDate),
  })
  .required();

export const fileSchema = yup.object({
  document: yup
    .mixed()
    .test(
      'fileType',
      'Only png/jpg/jpeg/pdf/doc/docx file type allowed.',
      (value: any) => {
        if (!value) return true; // Allow empty input
        const supportedFileTypes = [
          'png',
          'jpg',
          'jpeg',
          'pdf',
          'doc',
          'docx',
          'msword',
          'vnd.openxmlformats-officedocument.wordprocessingml.document',
        ];
        const fileType = value.type.split('/')[1]; // Extract file extension from MIME type
        return supportedFileTypes.includes(fileType.toLowerCase());
      },
    )
    .test('fileSize', ErrorMsg('10').fileSizeError, (value: any) => {
      if (!value) return true; // Allow empty input
      return value.size <= MaxFileSizeLimit;
    }),
});

export const thumbnailSchma = yup.object({
  file: yup
    .mixed()
    .test('fileType', 'Only png/jpg/jpeg file type allowed.', (value: any) => {
      if (!value) return true; // Allow empty input
      const supportedFileTypes = ['png', 'jpg', 'jpeg'];
      const fileType = value.type.split('/')[1]; // Extract file extension from MIME type
      return supportedFileTypes.includes(fileType.toLowerCase());
    })
    .test('fileSize', ErrorMsg('10').fileSizeError, (value: any) => {
      if (!value) return true; // Allow empty input
      return value.size <= MaxFileSizeLimit;
    }),
});
const HolidaysSchema = yup.object({
  holidays: yup.array().of(
    yup.object({
      holidayId: yup.string(),
      holidayName: yup
        .string()
        .required(ErrorMsg('Holiday Name').required)
        .min(3, ErrorMsg('Holiday Name', 3).minCharLimit)
        .max(50, ErrorMsg('Holiday Name', 50).maxCharLimit),
      rate: numberValidation(true).when('holidayName', {
        is: (val: string | undefined) => !!val, // Check if `holidayName` is present
        then: (schema) => schema.required(ErrorMsg('Rate').required), // Make `rate` required
        otherwise: (schema) => schema.nullable(), // Otherwise, allow it to be nullable
      }),
      date: yup
        .date()
        .when('holidayName', {
          is: (val: string | undefined) => !!val, // Check if `holidayName` is present
          then: (schema) => schema.required(ErrorMsg('Holiday Date').required), // Make `date` required
          otherwise: (schema) => schema.nullable(), // Otherwise, allow it to be nullable
        })
        .typeError(ErrorMsg('Holiday Date').valid),
      type: yup
        .string()
        .oneOf(
          Object.values(HOLIDAY_TYPES),
          `Type must be one of ${Object.values(HOLIDAY_TYPES).join(', ')}`,
        )
        .required(ErrorMsg('Type').required),
    }),
  ),
});
export const companyManagement = yup
  .object({
    businessName: yup
      .string()
      .required(ErrorMsg('Business Name').required)
      // .matches(
      //   /^[a-zA-Z0-9_\s]+$/,
      //   'Value can only contain letters, numbers, and underscores'
      // )
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).min),
    businessEmail: yup
      .string()
      .required(ErrorMsg('Email').required)
      .matches(emailRegex, ErrorMsg('email').valid)
      .max(100, ErrorMsg('Email', 100).maxCharLimit)
      .email(ErrorMsg('email').valid),
    department: yup
      .array()
      .min(1, 'Select at least one department')
      .required('Select at least one department'),
    businessDescription: yup
      .string()
      .max(500, ErrorMsg(500).max)
      .required(ErrorMsg('Description ').required),
    website: yup
      .string()
      .url('Invalid URL format. Please enter a valid website link.')
      .required(ErrorMsg('Website').required),
    establishedYear: yup
      .number()
      .typeError(ErrorMsg('Established year').required)
      .integer('Establishment year must be an integer.')
      .positive('Establishment year must be a positive number.')
      .min(1900, 'Establishment year must be greater than or equal to 1900.')
      .max(
        new Date().getFullYear(),
        'Establishment year cannot be in the future.',
      )
      .required(ErrorMsg('Established year').required),
    businessLocation: yup
      .object()
      .typeError(ErrorMsg('Business Location').required)
      .required(ErrorMsg('Business Location').required),
    businessAddress: yup.object().required(ErrorMsg('Address').required),

    // businessAddress: yup
    //   .string()
    //   .required(ErrorMsg('Address').required)
    //   .max(100, ErrorMsg('Address', 100).maxCharLimit),

    // businessAddress: Yup.lazy((value) =>
    //   value === null
    //     ? Yup.string().required(ErrorMsg('Address').required)
    //     : typeof value === 'object'
    //     ? Yup.object().shape({
    //         description: Yup.string().required(ErrorMsg('Street').required),
    //       })
    //     : Yup.string()
    //         .required(ErrorMsg('Address').required)
    //         .max(100, ErrorMsg('Address', 100).maxCharLimit),
    // ),
    businessZipCode: yup
      .string()
      .matches(regexForZipcode, 'Invalid Zip/Postal code.')
      .required(ErrorMsg('Zip/Postal code').required)
      .min(3, 'Invalid Zip/Postal code.')
      .max(9, 'Invalid Zip/Postal code.'),
    businessCity: yup.string().required(ErrorMsg('City').required),
    businessState: yup.string().required(ErrorMsg('State').required),
    logoImage: yup
      .mixed()
      .test(
        'fileExtension',
        'Only png/jpg/jpeg file type allowed.',
        (value: any) => {
          if (!value) return true; // Allow empty input
          const supportedFileTypes = ['png', 'jpg', 'jpeg'];
          const fileType = value.type.split('/')[1]; // Extract file extension from MIME type
          const fileName = value.name;
          const fileExtension = fileName.slice(
            ((fileName.lastIndexOf('.') - 1) >>> 0) + 2,
          );
          return supportedFileTypes.includes(fileExtension);

          // return supportedFileTypes.includes(fileType)
        },
      )
      .test('fileSize', ErrorMsg('10').fileSizeError, (value: any) => {
        if (!value) return true; // Allow empty input

        return value.size <= MaxFileSizeLimit;
      }),
    bgImage: yup
      .mixed()
      .test(
        'fileExtension',
        'Only png/jpg/jpeg file type allowed.',
        (value: any) => {
          if (!value) return true; // Allow empty input
          const supportedFileTypes = ['png', 'jpg', 'jpeg'];
          const fileType = value.type.split('/')[1]; // Extract file extension from MIME type
          const fileName = value.name;
          const fileExtension = fileName.slice(
            ((fileName.lastIndexOf('.') - 1) >>> 0) + 2,
          );
          return supportedFileTypes.includes(fileExtension);

          // return supportedFileTypes.includes(fileType)
        },
      )
      .test('fileSize', ErrorMsg('10').fileSizeError, (value: any) => {
        if (!value) return true; // Allow empty input

        return value.size <= MaxFileSizeLimit;
      }),
    files: yup
      .mixed()
      .test(
        'fileSize',
        'Total files should be less than 35MB',
        (value: any) => {
          if (!value) return true;
          const totalSize: any = Array.from(value).reduce(
            (acc, file: any) => acc + file.size,
            0,
          );
          return totalSize <= 36700160; // 35MB in bytes
        },
      )
      .test('fileType', 'Invalid file type', (value: any) => {
        if (!value) return true;

        const imageCount = Array.from(value).filter(
          (file: any) =>
            file.type === 'image/jpeg' ||
            file.type === 'image/jpg' ||
            file.type === 'image/png',
        ).length;

        const videoCount = Array.from(value).filter(
          (file: any) => file.type === 'video/mp4',
        ).length;

        return imageCount + videoCount === Array.from(value).length;
      })
      .test(
        'imageSize',
        'Each image should be less than 10MB',
        (value: any) => {
          if (!value) return true;
          return Array.from(value).every(
            (file: any) =>
              file.type.startsWith('video/')
                ? true
                : file.type.startsWith('image/') && file.size <= 10485760, // 10MB in bytes
          );
        },
      )
      .test(
        'videoSize',
        'Each video should be less than 50MB',
        (value: any) => {
          if (!value) return true;
          return Array.from(value).every(
            (file: any) =>
              file.type.startsWith('image/')
                ? true
                : file.type.startsWith('video/') && file.size <= 52428800, // 50MB in bytes
          );
        },
      ),

    zone: yup.object({
      name: yup
        .string()
        .required(ErrorMsg('Zone name').required)
        .max(25, ErrorMsg(25).max),
    }),
  })
  .concat(LegalDocSchema)
  .concat(HolidaysSchema)
  .required();

export const ProfilePersonalDetailsSchema = yup
  .object({})
  .concat(FirstNameSchema)
  .concat(LastNameSchema)
  .concat(MiddleNameSchema);

export const ProfileAddressDetailsSchema = yup
  .object({
    zipCode: yup
      .string()
      .matches(regexForZipcode, 'Invalid Zip code.')
      .required('Zip code is required.')
      .min(3, 'Invalid Zip code.')
      .max(9, 'Invalid Zip code.'),
    city: yup.string(),
    state: yup.string(),
    addressLineOne: yup
      .string()
      .max(500, 'Address must be at most 500 characters long'),
    addressLineTwo: yup
      .string()
      .max(500, 'Address must be at most 500 characters long'),
    country: yup.object(),
  })
  .required();

export const UpdatePasswordSchema = yup
  .object({
    currentPassword: yup
      .string()
      .required(ErrorMsg('Current Password').required)
      .min(8, ErrorMsg('Password', 8).minCharLimit)
      .max(128, ErrorMsg('Password', 128).maxCharLimit)
      .matches(passwordRegex, ErrorMsg().passwordError),
  })
  .concat(SetPasswordSchema)
  .required();

export const ResetPasswordFormSchema = yup
  .object({
    // email: yup.string().email().required(),
    password: yup
      .string()
      .min(8, ErrorMsg('Password', 8).minCharLimit)
      .max(128, ErrorMsg('Password', 128).maxCharLimit)
      .matches(passwordRegex, ErrorMsg().passwordError)
      .required(ErrorMsg('Password').required),
    confirmpassword: yup
      .string()
      .oneOf([yup.ref('password'), ''], 'Passwords must match')
      .required(ErrorMsg('Confirm Password').required),
  })
  .required();

export const BranchManagementScehema = yup
  .object({
    managers: yup.array().of(
      yup.object().shape({
        firstName: yup
          .string()
          .required(ErrorMsg('First Name').required)
          .min(3, ErrorMsg(3).min)
          .max(25, ErrorMsg(25).max)
          .matches(/^[a-zA-Z]+$/, ErrorMsg('First Name').onlyLetter),
        lastName: yup
          .string()
          .transform((value, originalValue) => {
            return originalValue === '' ? null : value;
          })
          .matches(/^[a-zA-Z]*$/, ErrorMsg('Last Name').onlyLetter)
          .min(3, ErrorMsg(3).min)
          .max(25, ErrorMsg(25).max)
          .nullable(),
        email: yup
          .string()
          .required(ErrorMsg('Email').required)
          .matches(emailRegex, ErrorMsg('email').valid)
          .max(50, ErrorMsg('Email', 50).maxCharLimit)
          .email(ErrorMsg('email').valid),
      }),
    ),
    branchName: yup
      .string()
      .required(ErrorMsg('Branch Name').required)
      .max(50, ErrorMsg('Branch Name', 50).maxCharLimit),
    // address: yup
    //   .string()
    //   .required(ErrorMsg('Address ').required)
    //   .max(100, ErrorMsg('Address', 100).maxCharLimit),
    address: yup.object().required(ErrorMsg('Address').required),
    zipCode: yup
      .string()
      .required(ErrorMsg('Zip code').required)
      .matches(regexForZipcode, 'Invalid Zip code.')
      .min(3, 'Invalid Zip code.')
      .max(9, 'Invalid Zip code.'),
    city: yup.string().required(ErrorMsg('City').required),
    state: yup.string().required(ErrorMsg('State').required),
    country: yup.string(),
    longitude: yup.string(),
    latitude: yup.string(),
    isAllProducts: yup.boolean().default(false),
    zone: yup.object({
      name: yup
        .string()
        .required(ErrorMsg('Zone name').required)
        .max(25, ErrorMsg('Zone name').max),
    }),
  })
  .concat(HolidaysSchema)
  .required();

export const CoVendorSchema = yup.object({
  firstName: yup
    .string()
    .required(ErrorMsg('First Name').required)
    .min(3, ErrorMsg(3).min)
    .max(50, ErrorMsg(50).max)
    .matches(/^[a-zA-Z]+$/, ErrorMsg('First Name').onlyLetter),
  lastName: yup
    .string()
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value;
    })
    .matches(/^[a-zA-Z]*$/, ErrorMsg('Last Name').onlyLetter)
    .max(50, ErrorMsg(50).max)
    .nullable(),
  email: yup
    .string()
    .required(ErrorMsg('Email').required)
    .matches(emailRegex, ErrorMsg('email').valid)
    .max(100, ErrorMsg('Email', 100).maxCharLimit)
    .email(ErrorMsg('email').valid),
});

export const AddAnotherDeliveryAddessSchema = yup.object({
  addressType: yup.string(),
  locationName: yup.object().when('addressType', {
    is: (val: string) => val === ADDRESS_TYPES.BUSINESS,
    then: (schema) => schema.required(ErrorMsg('Address').required),
  }),
  setName: yup.object().when('addressType', {
    is: (val: string) => val === ADDRESS_TYPES.BUSINESS,
    then: (schema) => schema.required(ErrorMsg('Set name').required),
  }),
  // episodeName: yup.object().when('addressType', {
  //   is: (val: string) => val === ADDRESS_TYPES.BUSINESS,
  //   then: (schema) => schema.required(ErrorMsg('Episode name').required),
  // }),
  apartment: yup
    .string()
    // .when('addressType', {
    //   is: (val: string) => val === ADDRESS_TYPES.PERSONAL,
    //   then: (schema) =>
    //     schema.required(ErrorMsg('Apatment/Suite Name').required),
    // })
    .min(3, ErrorMsg(3).min)
    .max(100, ErrorMsg(100).max),
  buildingName: yup
    .string()
    .when('addressType', {
      is: (val: string) => val === ADDRESS_TYPES.PERSONAL,
      then: (schema) => schema.required(ErrorMsg('Building Name').required),
    })
    .min(3, ErrorMsg(3).min)
    .max(100, ErrorMsg(100).max),
  landmark: yup.string().min(3, ErrorMsg(3).min).max(100, ErrorMsg(100).max),
  deliveryStatus: yup.string().when('addressType', {
    is: (val: string) => val === ADDRESS_TYPES.PERSONAL,
    then: (schema) => schema.required(ErrorMsg('Drop Off').required),
  }),
  setScheduleType: yup.object().when('addressType', {
    is: (val: string) => val === ADDRESS_TYPES.BUSINESS,
    then: (schema) => schema.required(ErrorMsg('Set Schedule Type').required),
  }),
  address: yup
    .string()
    .when('addressType', {
      is: (val: string) => val === ADDRESS_TYPES.PERSONAL,
      then: (schema) => schema.required(ErrorMsg('Location').required),
    })
    .min(3, ErrorMsg(3).min)
    .max(100, ErrorMsg(100).max),
});

export const AddAddressDraft = yup.object({
  addressType: yup.string(),
  setName: yup.string().when('addressType', {
    is: (val: string) => val === ADDRESS_TYPES.BUSINESS,
    then: (schema) =>
      schema
        .required(ErrorMsg('Set name').required)
        .min(3, ErrorMsg(3).min)
        .max(50, ErrorMsg(50).max),
  }),
  episodeName: yup.string().when('productionType', {
    is: (val: string) => val === PRODUCTION_TYPE.TV_SERIES,
    then: (schema) =>
      schema
        .required(ErrorMsg('Episode name').required)
        .min(3, ErrorMsg(3).min)
        .max(50, ErrorMsg(50).max),
  }),
  productionName: yup.string().when('addressType', {
    is: (val: string) => val === ADDRESS_TYPES.BUSINESS,
    then: (schema) =>
      schema
        .required(ErrorMsg('Production Name').required)
        .min(3, ErrorMsg(3).min)
        .max(50, ErrorMsg(50).max),
  }),
  productionType: yup.string().when('addressType', {
    is: (val: string) => val === ADDRESS_TYPES.BUSINESS,
    then: (schema) =>
      schema
        .required(ErrorMsg('Production Type').required)
        .min(3, ErrorMsg(3).min)
        .max(50, ErrorMsg(50).max),
  }),
  apartment: yup
    .string()
    .min(3, ErrorMsg(3).min)
    .max(50, ErrorMsg(50).max)
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value;
    }),
  buildingName: yup
    .string()
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value;
    })
    .min(3, ErrorMsg(3).min)
    .max(50, ErrorMsg(50).max),
  landmark: yup
    .string()
    .min(3, ErrorMsg(3).min)
    .max(50, ErrorMsg(50).max)
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value;
    })
    .nullable(),

  email: yup
    .string()
    .required(ErrorMsg('Email').required)
    .matches(emailRegex, ErrorMsg('email').valid)
    .max(50, ErrorMsg('Email', 50).maxCharLimit)
    .email(ErrorMsg('email').valid),
  phoneNo: yup
    .string()
    .required(ErrorMsg('Mobile number').required)
    .min(5, ErrorMsg(5).min)
    .max(13, ErrorMsg(13).max),
  countryCode: yup.string(),
  userName: yup
    .string()
    .required(ErrorMsg('User name').required)
    .min(3, ErrorMsg(3).min)
    .max(50, ErrorMsg(50).max),
  zipCode: yup.string().when('addressType', {
    is: (val: string) => val === ADDRESS_TYPES.PERSONAL,
    then: (schema) =>
      schema
        .required(ErrorMsg('Zipcode').required)
        .min(3, ErrorMsg(3).min)
        .max(10, ErrorMsg(10).max),
  }),
  locationName: yup
    .string()
    .required(ErrorMsg('Location').required)
    .min(3, ErrorMsg(3).min),
});

export type CancelFormInterface = yup.InferType<typeof CancelFormSchema>;
export const CancelFormSchema = yup.object({
  cancelReason: yup.string().required(ErrorMsg('Reason').required),
  cancelNotes: yup.string().max(500, ErrorMsg(500).max),
});

export type CancelFormInterfaceRequest = yup.InferType<
  typeof CancelFormSchemaRequest
>;
export const CancelFormSchemaRequest = yup.object({
  cancelReason: yup.string().required(ErrorMsg('Reason').required),
  cancelNotes: yup.string().required(ErrorMsg('Description').required),
});
export const AvailabilityFormSchema = yup.object().shape({
  fromDate: yup.date().required('From date is required'),
  toDate: yup
    .date()
    .required('To date is required')
    .min(yup.ref('fromDate'), 'To date must be after from date'),
  reason: yup
    .string()
    .required('Reason is required')
    .max(500, 'Reason cannot exceed 500 characters'),
});

export const QtySchemaRequired = yup.object({
  qty: yup
    .number()
    .typeError('Must be a number')
    .required(ErrorMsg('Quantity').required)
    .test('is-decimal', 'Invalid number', (value) =>
      value !== undefined
        ? /^[+-]?([0-9]*[.])?[0-9]+$/.test(value.toString())
        : true,
    ),
});

export const QtySchemaNoNRequired = yup.object({
  qty: yup
    .number()
    .typeError('Must be a number')
    .test('is-decimal', 'Invalid number', (value) =>
      value !== undefined
        ? /^[+-]?([0-9]*[.])?[0-9]+$/.test(value.toString())
        : true,
    ),
});

export const AddProductsToOrderSchema = yup.object({
  products: yup.array().of(
    yup.object({
      productType: yup.string(),
      // qty: yup
      //   .number()
      //   .typeError('Enter a valid number')
      //   .required(ErrorMsg('Quantity').required)
      //   .min(1, ErrorMsg(3).min)
      //   .default(1),
      // shiftLength: yup.string().when('productType', {
      //   is: (val: string) => val === PRODUCT_TABS.service.value,
      //   then: (schema) => schema.required(ErrorMsg('Shift Length').required),
      // }),
      isRushApply: yup.boolean().default(false),
      rentalDetails: yup
        .object()
        .when('productType', {
          is: (val: string) => val === PRODUCT_TABS.service.value,
          then: (schema) => schema.required(ErrorMsg('Retal Details').required),
        })
        .nullable(),
      cartId: yup
        .string()
        // .when('productType', {
        //   is: (val: string) => val === PRODUCT_TABS.service.value,
        //   then: (schema) =>
        //     schema),
        // })
        .required(ErrorMsg('Please check rental details').required),
    }),
  ),
});

export const RentalDetailSchema = yup.object({
  productType: yup.string(),
  startDate: yup
    .date()
    .required(ErrorMsg('Start Date').required)
    .min(new Date(), ErrorMsg().futureDate),
  endDate: yup
    .date()
    .required(ErrorMsg('End Date').required)
    .min(new Date(), ErrorMsg().futureDate),
  shiftCoverage: yup.string().when('productType', {
    is: (val: string) => val === PRODUCT_TABS.service.value,
    then: (schema) => schema.required(ErrorMsg('Shift Coverage').required),
  }),
  startTime: yup.date().required('Start time is required'),
  endTime: yup.date().required('End time is required'),
  shiftLength: yup.string().when('productType', {
    is: (val: string) => val === PRODUCT_TABS.service.value,
    then: (schema) => schema.required(ErrorMsg('Shift Length').required),
  }),
  notes: yup.string().max(500, ErrorMsg(500).max),
});

export const RentalDetailProductSchema = yup.object({
  productType: yup.string(),
  startDate: yup
    .date()
    .required(ErrorMsg('Start Date').required)
    .min(new Date(), ErrorMsg().futureDate),
  endDate: yup
    .date()
    .required(ErrorMsg('End Date').required)
    .min(new Date(), ErrorMsg().futureDate),
  startTime: yup.date(),
  endTime: yup.date(),
  rentPriceId: yup.string(),
  notes: yup.string().max(500, ErrorMsg(500).max),
});

// Custom method to ensure unique values in an array
// Custom method to check for unique values
// Extend y with a custom validation method for unique discount codes
yup.addMethod(yup.array, 'uniqueDiscount', function (message, mapper) {
  return this.test('unique', message, function (list) {
    if (!Array.isArray(list)) return true;

    const map: any = {};
    for (let i = 0; i < list.length; i++) {
      const item = mapper(list[i]);
      if (map[item]) {
        return this.createError({
          path: `${this.path}[${i}].discountCode`,
          message,
        });
      }
      map[item] = true;
    }
    return true;
  });
});
// Reusable validation schemas
const productTypeSchema = yup.string().max(50, ErrorMsg('Product Type').max);

const discountTypeSchema = yup.string().max(50, ErrorMsg('Discount Type').max);

const discountSchema = yup.string().when('productType', {
  is: (productType: any) => !!productType,
  then: () => yup.string().required(ErrorMsg('Discount').required),
  otherwise: () => yup.string(),
});

const departmentSchema = yup.array().when('productType', {
  is: (productType: any) => !!productType,
  then: () =>
    yup
      .object()
      .nullable()
      .required('Please select department.')
      .test(
        'is-not-empty',
        'The object must have at least one key',
        (value) => value && Object.keys(value).length > 0,
      ),
  otherwise: () => yup.object().nullable(),
});

const categorySchema = yup.array().when('productType', {
  is: (productType: any) => !!productType,
  then: () =>
    yup
      .object()
      .nullable()
      .required('Please select category.')
      .test(
        'is-not-empty',
        'The object must have at least one key',
        (value) => value && Object.keys(value).length > 0,
      ),
  otherwise: () => yup.object().nullable(),
});

const productSchema = yup.array().when('productType', {
  is: (productType: any) => !!productType,
  then: () =>
    yup
      .object()
      .nullable()
      .required('Item is required')
      .test('is-not-empty', 'Item is required', (value: any) => {
        return value && value?.title !== '';
      }),
  otherwise: () => yup.array().nullable(),
});
const discountCodeSchema = yup.string().when('discount', {
  is: (discount: any) => !!discount,
  then: () => {
    return yup
      .string()
      .nullable()
      .required('Discount Code is required.')
      .matches(
        /^[A-Z0-9]+$/,
        'Value can only contain capital letters and numbers',
      );
  },
  otherwise: () => yup.string(),
});

const usageLimitSchema = yup.string().when('discount', {
  is: (discount: any) => !!discount,
  then: () => yup.string().required('Limit is required.'),
  otherwise: () => yup.string(),
});

// Main validation schema
const RatesAndDiscountsSchema = yup
  .object({
    productDiscount: yup.array().of(
      yup.object().shape({
        // Uncomment if productType is required
        // productType: productTypeSchema,
        discountType: discountTypeSchema,
        discount: yup.string(),
      }),
    ),
    categoryDiscount: yup.array().of(
      yup.object().shape({
        productType: productTypeSchema,
        discountType: discountTypeSchema,
        discount: discountSchema,
        department: departmentSchema,
        category: categorySchema,
        itemId: productSchema,
      }),
    ),
    custom: yup
      .array()
      .of(
        yup.object().shape({
          discount: yup.string(),
          discountCode: discountCodeSchema,
          usageLimit: usageLimitSchema,
        }),
      )
      .uniqueDiscount(
        'The discount field should be unique.',
        (item: any) => item?.discountCode,
      ),
  })
  .required();

export default RatesAndDiscountsSchema;

export const ClientDoucmentSchema = yup
  .object({
    docType: yup
      .string()
      .required(ErrorMsg('Document type').required)
      .matches(/^[A-Za-z ]*$/, 'Only alphabets are allowed')
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).max),
    docName: yup
      .string()
      .required(ErrorMsg('Document Name').required)
      .matches(/^[A-Za-z ]*$/, 'Only alphabets are allowed')
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).max),
    documentUrl: yup
      .mixed()
      .test(
        'fileExtension',
        'Only png/jpg/jpeg/pdf/doc/docx file type allowed.',
        (value: any) => {
          if (!value) return true; // Allow empty input
          const supportedFileTypes = [
            'png',
            'jpg',
            'jpeg',
            'pdf',
            'doc',
            'docx',
            'msword',
          ];
          const fileName = value.name;
          if (fileName) {
            const fileExtension = fileName.slice(
              ((fileName.lastIndexOf('.') - 1) >>> 0) + 2,
            );
            return supportedFileTypes.includes(fileExtension.toLowerCase());
          } else {
            return false;
          }
        },
      )
      .test('fileSize', ErrorMsg('10').fileSizeError, (value: any) => {
        if (!value) return true; // Allow empty input
        return value.size <= MaxFileSizeLimit;
      })
      .required(ErrorMsg('Document').required),
  })
  .required();

export const AddProductVariantSchema = yup
  .object({
    title: nullableStringValidation
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).max)
      .required(ErrorMsg('Title').required),
    productModel: nullableStringValidation
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).max),
    sku: yup
      .string()
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).max)
      .matches(/^\S*$/, 'Invalid SKU code')
      .required(ErrorMsg('SKU').required),
    // .default(1), // Optional: Ensure the number is an integer,
    description: yup.string().max(500, ErrorMsg(500).max),
    price: numberValidation(true).required(ErrorMsg('Price').required),
    files: yup
      .mixed()
      .test(
        'fileSize',
        'Total files should be less than 35MB',
        (value: any) => {
          if (!value) return true;
          const totalSize: any = Array.from(value).reduce(
            (acc, file: any) => acc + file.size,
            0,
          );
          return totalSize <= 36700160;
        },
      )
      .test('fileType', 'Invalid file type', (value: any) => {
        if (!value) return true;

        const imageCount = Array.from(value).filter(
          (file: any) =>
            file.type === 'image/jpeg' ||
            file.type === 'image/jpg' ||
            file.type === 'image/png',
        ).length;

        const videoCount = Array.from(value).filter(
          (file: any) => file.type === 'video/mp4',
        ).length;

        return imageCount + videoCount === Array.from(value).length;
      })
      .test('imageSize', 'Each image should be less than 3MB', (value: any) => {
        if (!value) return true;
        return Array.from(value).every((file: any) =>
          file.type.startsWith('video/')
            ? true
            : file.type.startsWith('image/') && file.size <= 3145728,
        ); // 3MB in bytes
      })
      .test('videoSize', 'Video should be less than 30MB', (value: any) => {
        if (!value) return true;
        return Array.from(value).every((file: any) =>
          file.type.startsWith('image/')
            ? true
            : file.type.startsWith('video/') && file.size <= 31457280,
        );
      }),
  })
  .concat(AttributesSchema)
  .required();

const mailingaddress = yup.object().shape({
  address: yup
    .string()
    // .required(ErrorMsg('Address').required)
    // .min(3, ErrorMsg(3).min)
    .max(50, ErrorMsg(50).max),
  zipCode: yup
    .string()
    .matches(regexForZipcode, 'Invalid Zip/Postal code.')
    .required(ErrorMsg('Zip/Postal code').required)
    .min(3, 'Invalid Zip/Postal code.')
    .max(9, 'Invalid Zip/Postal code.'),
  // city: yup.string().required(ErrorMsg('City').required),
  // state: yup.string().required(ErrorMsg('State').required),
  // location: yup.string().required(ErrorMsg('Location').required),
});

// Reusable validation schemas
const studioNameSchema = yup.mixed().nullable();
const companyNameSchema = yup.mixed().nullable();
const certificateTypeschema = yup.string();
const degreeTypeTypeschema = yup.mixed().nullable();
const countrySchema = yup.mixed().nullable();
const type = yup
  .mixed()
  .required(ErrorMsg('Type').required)
  .nullable()
  .test('is-not-empty', ErrorMsg('Type').required, (value) => value !== '');

const tier = yup
  .mixed()
  .required(ErrorMsg('Tier').required)
  .nullable()
  .test('is-not-empty', ErrorMsg('Tier').required, (value) => value !== '');

// const tier = yup.string().when('type', {
//   is: (type: any) => !!type,
//   then: () => yup.string().required(ErrorMsg('Tier').required),
//   otherwise: () => yup.string(),
// });
const citySchema = yup.string().nullable();
const stateSchema = yup.string().when('city', {
  is: (city: any) => !!city,
  then: () => yup.string().required(ErrorMsg('State').required),
  otherwise: () => yup.string(),
});

const rolenameproffSchema = yup.string().when('companyName', {
  is: (companyName: any) => !!companyName,
  then: () => yup.string().required(ErrorMsg('Role Name').required),
  otherwise: () => yup.string(),
});

const startDateSchemaattach = yup.string().when('certificateType', {
  is: (certificateType: any) => !!certificateType,
  then: () =>
    yup
      .date()
      .required('Issue Date is required')
      .typeError('Issue Date must be a valid date'),
  otherwise: () => yup.mixed().notRequired().nullable(),
});

const endDateSchemaattach = yup.string().when('certificateType', {
  is: (certificateType: any) => !!certificateType,
  then: () =>
    yup
      .date()
      .required('Expiry Date is required')
      .typeError('Expiry Date must be a valid date')
      .min(yup.ref('startDate'), 'End Date must be later than start date')
      .nullable(),
  otherwise: () => yup.mixed().notRequired().nullable(),
});

const attachmentSchema = yup.string().when('certificateType', {
  is: (certificateType: any) => !!certificateType,
  then: () => yup.string().required(ErrorMsg('Attachment').required),
  otherwise: () => yup.mixed().notRequired().nullable(),
});

// const certificateNameSchema = yup.string();

const startDateSchemaprof = yup.string().when('companyName', {
  is: (companyName: any) => !!companyName,
  then: () =>
    yup
      .date()
      .required('Start Date is required')
      .typeError('Start Date must be a valid date'),
  otherwise: () => yup.mixed().notRequired().nullable(),
});

const endDateSchemaprof = yup.string().when('companyName', {
  is: (companyName: any) => !!companyName,
  then: () =>
    yup
      .date()
      .required('End Date is required')
      .typeError('End Date must be a valid date')
      .min(yup.ref('startDate'), 'End Date must be later than start date'),
  otherwise: () => yup.mixed().notRequired().nullable(),
});

const companyLocationSchemaProf = yup.string().when('companyName', {
  is: (companyName: any) => !!companyName,
  then: () =>
    yup
      .string()
      .required(ErrorMsg('Company Location').required)
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).max),
  otherwise: () => yup.string(),
});
const departmentIdschema = yup.string().when('companyName', {
  is: (companyName: any) => !!companyName,
  then: () => yup.string().required(ErrorMsg('Department Type').required),
  otherwise: () => yup.string(),
});
// const schoolschema = yup.string().when('degreeType', {
//   is: (degreeType: any) => !!degreeType,
//   then: () => yup.string().required(ErrorMsg('School').required),
//   otherwise: () => yup.string(),
// });

const schoolSchema = yup.mixed().when('degreeType', {
  is: (degreeType: any) => !!degreeType,
  then: () =>
    yup
      .mixed()
      .test(
        'is-valid-type',
        'School must be a string or an object',
        (value) =>
          (typeof value === 'string' && value.trim() !== '') ||
          (typeof value === 'object' && value !== null),
      )
      .required(ErrorMsg('School Name').required),
  otherwise: () => yup.mixed().nullable(),
});

const fieldofstudy = yup.string().when('degreeType', {
  is: (degreeType: any) => !!degreeType,
  then: () => yup.string().required(ErrorMsg('Field of Study').required),
  otherwise: () => yup.string(),
});

const descriptionSchemaProf = yup.string().when('companyName', {
  is: (companyName: any) => !!companyName,
  then: () => yup.string().required(ErrorMsg('Description').required),
  otherwise: () => yup.string(),
});

const roleNameSchema = yup.string().when('studioName', {
  is: (studioName: any) => !!studioName,
  then: () => yup.string().required(ErrorMsg('Role Name').required),
  otherwise: () => yup.string(),
});
const productionNameSchema = yup.mixed().when('studioName', {
  is: (studioName: any) => !!studioName || !!studioName?.name,
  then: () =>
    yup
      .mixed()
      .test(
        'is-valid-type',
        'Production Name must be a string or an object',
        (value) =>
          typeof value === 'string' ||
          (typeof value === 'object' && value !== null),
      )
      .required(ErrorMsg('Production Name').required),
  otherwise: () => yup.mixed().nullable(),
});
const startDateSchema = yup.string().when('studioName', {
  is: (studioName: any) => !!studioName,
  then: () =>
    yup
      .date()
      .required('Start Date is required')
      .typeError('Start Date must be a valid date'),
  otherwise: () => yup.mixed().notRequired().nullable(),
});

const endDateSchema = yup.string().when('studioName', {
  is: (studioName: any) => !!studioName,
  then: () =>
    yup
      .date()
      .required('End Date is required')
      .typeError('End Date must be a valid date')
      .min(yup.ref('startDate'), 'End Date must be later than start date'),
  otherwise: () => yup.mixed().notRequired().nullable(),
});

const startDateSchemaEdu = yup.string().when('degreeType', {
  is: (degreeType: any) => !!degreeType,
  then: () =>
    yup
      .date()
      .required('Start Date is required')
      .typeError('Start Date must be a valid date'),
  otherwise: () => yup.mixed().notRequired().nullable(),
});

const endDateSchemaEdu = yup.string().when('degreeType', {
  is: (degreeType: any) => !!degreeType,
  then: () =>
    yup
      .date()
      .required('End Date is required')
      .typeError('End Date must be a valid date')
      .min(yup.ref('startDate'), 'End Date must be later than start date')
      .nullable(),
  otherwise: () => yup.mixed().notRequired().nullable(),
});

const productionLocationSchema = yup.string().when('studioName', {
  is: (studioName: any) => !!studioName,
  then: () =>
    yup
      .string()
      .required(ErrorMsg('Production Location').required)
      .min(3, ErrorMsg(3).min)
      .max(50, ErrorMsg(50).max),
  otherwise: () => yup.string(),
});

const descriptionSchema = yup.string().when('studioName', {
  is: (studioName: any) => !!studioName,
  then: () => yup.string().required(ErrorMsg('Description').required),
  otherwise: () => yup.string(),
});

export const AddSecurityManagerFormSchema = yup.object().shape({
  profilePicture: yup.string().required(ErrorMsg('Profile Picture').required),
  country: yup.mixed().required(ErrorMsg('Country').required),
  city: yup
    .mixed()
    .test('is-string-or-object', ErrorMsg('City').required, function (value) {
      // If value is a string and not empty, it's valid
      if (typeof value === 'string' && value.trim() !== '') {
        return true;
      }
      // If value is an object and contains the required property (e.g., name), it's valid
      if (typeof value === 'object' && value !== null) {
        return true;
      }
      // If neither condition is met, return false (validation fails)
      return false;
    })
    .required(ErrorMsg('City').required),
  state: yup.string().required(ErrorMsg('State').required),

  first_name: yup
    .string()
    .required(ErrorMsg('First Name').required)
    .min(3, ErrorMsg(3).min)
    .max(25, ErrorMsg(25).max)
    .matches(/^[a-zA-Z]+$/, ErrorMsg('First Name').onlyLetter),
  last_name: yup
    .string()
    .required(ErrorMsg('Last Name').required)
    .min(3, ErrorMsg(3).min)
    .max(25, ErrorMsg(25).max)
    .matches(/^[a-zA-Z]+$/, ErrorMsg('Last Name').onlyLetter),
  about: yup.string().required(ErrorMsg('About').required),
  email: yup
    .string()
    .required(ErrorMsg('Email').required)
    .matches(emailRegex, ErrorMsg('Email').valid)
    .max(50, ErrorMsg('Email', 50).maxCharLimit),
  position: yup
    .string()
    .required(ErrorMsg('Position').required)
    .min(3, ErrorMsg(3).min)
    .max(50, ErrorMsg(50).max)
    .matches(
      /^[a-zA-Z0-9.,!@#$%^&*()_+\-=~\[\]{}'";:/?\\|` ]+$/,
      ErrorMsg('Position').onlyLetter,
    ),
  mobile_number: yup
    .string()
    .required(ErrorMsg('Mobile Number').required)
    .min(5, ErrorMsg(5).min)
    .max(13, ErrorMsg(13).max),

  productionExperience: yup.array().of(
    yup.object().shape({
      studioName: studioNameSchema,
      roleName: roleNameSchema,
      startDate: startDateSchema,
      endDate: endDateSchema,
      description: descriptionSchema,
      productionLocation: productionLocationSchema,
      productionName: productionNameSchema,
    }),
  ),

  additionalResidence: yup.array().of(
    yup.object().shape({
      country: countrySchema,
      city: citySchema,
      state: stateSchema,
    }),
  ),
  typeAndTier: yup
    .array()
    .of(
      yup.object().shape({
        type: type, // Replace with the correct validation schema for `type`
        tier: tier, // Replace with the correct validation schema for `tier`
      }),
    )
    .min(1, 'At least one entry is required'),

  professionalExperience: yup.array().of(
    yup.object().shape({
      companyName: companyNameSchema,
      roleName: rolenameproffSchema,
      startDate: startDateSchemaprof,
      endDate: endDateSchemaprof,
      description: descriptionSchemaProf,
      companyLocation: companyLocationSchemaProf,
      departmentId: departmentIdschema,
    }),
  ),

  certificationQualification: yup.array().of(
    yup.object().shape({
      certificateType: certificateTypeschema,
      startDate: startDateSchemaattach,
      endDate: endDateSchemaattach,
      attachment: attachmentSchema,
      // companyName : companyNameSchemaCertification,
      certificationName: yup
        .string()
        // .nullable()
        .when('certificateType', {
          is: (value: any) => value === 'Others',
          then: (schema) =>
            schema.required('Certificate name is required when type is OTHERS'),
          // otherwise: (schema) => schema.nullable(),
        }) as yup.StringSchema<string | null | undefined, Record<string, any>>,
    }),
  ),

  educationQualification: yup.array().of(
    yup.object().shape({
      degreeType: degreeTypeTypeschema,
      fieldOfStudy: fieldofstudy,
      startDate: startDateSchemaEdu,
      endDate: endDateSchemaEdu,
      // degree: degreeschema,
      school: schoolSchema,
    }),
  ),

  businessAddress: mailingaddress.required(
    ErrorMsg('Mailing Address is required'),
  ),
  securityRelatedFieldYears: yup
    .string()
    .nullable()
    .when('typeAndTier', (typeAndTier, schema) => {
      const hasValidType = typeAndTier[0]?.map((t: any) => {
        if (
          t?.type === 'Security Advisor' ||
          t?.type === 'Executive Protection'
        ) {
          return (
            t?.type === 'Security Advisor' || t?.type === 'Executive Protection'
          );
        } else {
          return false;
        }
      });
      if (hasValidType.includes(true)) {
        return schema.required(
          ErrorMsg('Security related field years').required,
        );
      }

      return schema;
    }),

  securityAdvisorYears: yup
    .string()
    .nullable()
    .when('typeAndTier', (typeAndTier, schema) => {
      const hasValidType = typeAndTier[0]?.map((t: any) => {
        if (
          t?.type === 'Security Advisor' ||
          t?.type === 'Executive Protection'
        ) {
          return (
            t?.type === 'Security Advisor' || t?.type === 'Executive Protection'
          );
        } else {
          return false;
        }
      });
      if (hasValidType.includes(true)) {
        return schema.required(ErrorMsg('Security advisor years').required);
      }

      return schema;
    }),

  safetyRelatedFieldYears: yup
    .string()
    .nullable()
    .when('typeAndTier', (typeAndTier, schema) => {
      const hasValidType = typeAndTier[0]?.map((t: any) => {
        if (t?.type === 'Safety Advisor') {
          return t?.type === 'Safety Advisor';
        } else {
          return false;
        }
      });

      if (hasValidType.includes(true)) {
        return schema.required(ErrorMsg('Safety related field years').required);
      }

      return schema;
    }),

  safetyAdvisorYears: yup
    .string()
    .nullable()
    .when('typeAndTier', (typeAndTier, schema) => {
      const hasValidType = typeAndTier[0]?.map((t: any) => {
        if (t?.type === 'Safety Advisor') {
          return t?.type === 'Safety Advisor';
        } else {
          return false;
        }
      });
      if (hasValidType.includes(true)) {
        return schema.required(ErrorMsg('Safety advisor years').required);
      }

      return schema;
    }),

  numberOfFilmTvProduction: yup.string().nullable(),
  numberOfFilmTvProductionSafety: yup.string().nullable(),

  skillAndTrainingDescription: yup
    .string()
    .nullable()
    .when('typeAndTier', (typeAndTier, schema) => {
      const hasValidType = typeAndTier[0]?.map((t: any) => {
        if (
          t?.type === 'Security Advisor' ||
          t?.type === 'Executive Protection'
        ) {
          return (
            t?.type === 'Security Advisor' || t?.type === 'Executive Protection'
          );
        } else {
          return false;
        }
      });
      if (hasValidType.includes(true)) {
        return schema.required(
          ErrorMsg('Skill and Training Description').required,
        );
      }
      return schema;
    }),

  skillAndTrainingDescriptionSafety: yup
    .string()
    .nullable()
    .when('typeAndTier', (typeAndTier, schema) => {
      const hasValidType = typeAndTier[0]?.map((t: any) => {
        if (t?.type === 'Safety Advisor') {
          return t?.type === 'Safety Advisor';
        } else {
          return false;
        }
      });
      if (hasValidType.includes(true)) {
        return schema.required(
          ErrorMsg('Skill and Training Safety Description').required,
        );
      }

      return schema;
    }),
});

export const AddStudioSchema = yup.object().shape({
  studioName: yup
    .string()
    .required(ErrorMsg('Name').required)
    .min(3, ErrorMsg(3).min)
    .max(50, ErrorMsg(50).max)
    .matches(
      /^[a-zA-Z0-9.,!@#$%^&*()_+\-=~\[\]{}'";:/?\\|` ]+$/,
      ErrorMsg('Name').onlyLetter,
    ),
  link: yup
    .string()
    .url('Invalid URL format. Please enter a valid website link.'),
  // .required(ErrorMsg('Link').required),
  document: yup.string(),
});

// ADD GROUP VALIDATION

export const AddGroupSchema = yup
  .object({
    // department: yup.string().required('Please select department.'),
    sequenceNo: yup.string().nullable().default(null),
    isFeatureGroup: yup.boolean().default(false),
    department: yup
      .object()
      .required('Please select department.')
      .test(
        'is-not-empty',
        'The object must have at least one key',
        (value) => value && Object.keys(value).length > 0,
      ),

    category: yup
      .object()
      .required('Please select category.')
      .test(
        'is-not-empty',
        'The object must have at least one key',
        (value) => value && Object.keys(value).length > 0,
      ),
    // type: yup.string().required('Please select Type.'),
    title: yup
      .string()
      .required(ErrorMsg('Group title').required)
      .min(3, ErrorMsg(3).min)
      .max(60, ErrorMsg(60).max),

    description: yup.string().max(5000, ErrorMsg(5000).max),

    files: yup
      .mixed()
      .test('fileType', 'Invalid file type', (value: any) => {
        if (!value) return true;

        const imageCount = Array.from(value).filter(
          (file: any) =>
            file.type === 'image/jpeg' ||
            file.type === 'image/jpg' ||
            file.type === 'image/png',
        ).length;

        const videoCount = Array.from(value).filter(
          (file: any) => file.type === 'video/mp4',
        ).length;

        return imageCount + videoCount === Array.from(value).length;
      })
      .test(
        'imageSize',
        'Each image should be less than 10MB',
        (value: any) => {
          if (!value) return true;
          return Array.from(value).every(
            (file: any) =>
              file.type.startsWith('video/')
                ? true
                : file.type.startsWith('image/') && file.size <= 10485760, // 10MB in bytes
          );
        },
      )
      .test(
        'videoSize',
        'Each video should be less than 50MB',
        (value: any) => {
          if (!value) return true;
          return Array.from(value).every(
            (file: any) =>
              file.type.startsWith('image/')
                ? true
                : file.type.startsWith('video/') && file.size <= 52428800, // 50MB in bytes 48060415
          );
        },
      ),
  })
  .required();

import * as Yup from 'yup';
import dayjs from 'dayjs';

const today = dayjs().endOf('day'); // Define "today" as the current day
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'; // ES 2015
const tomorrow = dayjs().add(1, 'day').endOf('day');
dayjs.extend(isSameOrAfter);

export const DateRangeSchema = Yup.object({
  fromDate: Yup.date()
    .typeError('Invalid date')
    .nullable()
    .required(ErrorMsg('From date').required)
    .test(
      'is-valid-date',
      'Invalid from date',
      (value) => value && dayjs(value).isValid(),
    )
    .test(
      'is-not-in-future',
      'From date cannot be in the future',
      (value) =>
        value && dayjs(value).isValid() && dayjs(value).isBefore(today, 'day'),
    ),
  toDate: Yup.date()
    .typeError('Invalid date')
    .nullable()
    .required(ErrorMsg('To date').required)
    .test(
      'is-valid-date',
      'Invalid to date',
      (value) => value && dayjs(value).isValid(),
    )
    .test(
      'is-not-in-future',
      'To date cannot be in the future',
      (value) =>
        value &&
        dayjs(value).isValid() &&
        dayjs(value).isBefore(tomorrow, 'day'),
    )
    .when('fromDate', (fromDate, schema) => {
      return fromDate && dayjs(fromDate).isValid()
        ? schema.test(
            'is-after-start-date',
            'To date must be after or same as from date',
            (toDate) =>
              toDate &&
              dayjs(toDate).isValid() &&
              dayjs(toDate).isSameOrAfter(dayjs(fromDate), 'day'),
          )
        : schema;
    }),
});

export const DateRangeFutureSchema = Yup.object({
  fromDate: Yup.date()
    .typeError('Invalid date')
    .nullable()
    .required(ErrorMsg('From date').required)
    .test(
      'is-valid-date',
      'Invalid from date',
      (value) => value && dayjs(value).isValid(),
    ),

  toDate: Yup.date()
    .typeError('Invalid date')
    .nullable()
    .required(ErrorMsg('To date').required)
    .test(
      'is-valid-date',
      'Invalid to date',
      (value) => value && dayjs(value).isValid(),
    )
    .when('fromDate', (fromDate, schema) => {
      return fromDate && dayjs(fromDate).isValid()
        ? schema.test(
            'is-after-start-date',
            'To date must be after or same as from date',
            (toDate) =>
              toDate &&
              dayjs(toDate).isValid() &&
              dayjs(toDate).isSameOrAfter(dayjs(fromDate), 'day'),
          )
        : schema;
    }),
});
export const EditShiftSchema = yup.object({
  shiftCoverage: yup.string().required(ErrorMsg('Shift Coverage').required),
  startTime: yup.date().required(ErrorMsg('Start time').required),
  endTime: yup.date().required(ErrorMsg('End time').required),
});
