import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useAppSelector } from '../redux/store';

interface Props {
  listData: any;
  setPageChange: (page: number) => void;
}

export default function TablePagination({ listData, setPageChange }: Props) {
  const totalPages = listData?.totalPage;
  const { page } = useAppSelector((state) => state.common);
  return (
    <Pagination
      shape="rounded"
      color="primary"
      variant="outlined"
      className="reelPagination"
      boundaryCount={3}
      siblingCount={0}
      page={page}
      renderItem={(item) => (
        <PaginationItem
          slots={{
            previous: ArrowBackIcon,
            next: ArrowForwardIcon,
          }}
          {...item}
        />
      )}
      onChange={(e, val: number) => setPageChange(val)}
      count={totalPages}
    />
  );
}
